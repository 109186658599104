import { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import ProductCartItem from "./productCartItem";
import OrderSummary from "./orderSummary";
import { DataContext } from "../../context/DataContext";
import DateSelectionWithoutVoucher from "../booking/dateSelectionWithoutVoucher";

export default function ShoppingCart({ course, selectedLesson, setSelectedLesson }) {


  const checkoutURL = process.env.REACT_APP_URI + "/v1/payments/checkout";
  const { data, updateData } = useContext(DataContext);
  const [otherRequest, setOtherRequest] = useState('');
  const navigate = useNavigate();
  const [totalToPay, setTotalToPay] = useState(0);
  const [couponId, setCouponId] = useState('');



  const location = useLocation();
  const classSelected = location.state;
  const [quantity, setQuantity] = useState(1);
  const [classWanted, setClassWanted] = useState();

  const [personalData, setPersonalData] = useState({
    name: "",
    email: "",
    phone: "",
    birthDate: "",
    specialRequests: [],
  });
  const [giftData, setGiftData] = useState({
    from: "",
    toName: "",
    emailTo: "",
    message: "",
  });
  const [isAGift, setIsAGift] = useState(false);
  const [dateIsWanted, setDateIsWanted] = useState(selectedLesson ? true : false);

  useEffect(() => {
    selectedLesson && setClassWanted(selectedLesson)
  }, [])




  useEffect(() => {
    if (!dateIsWanted) {
      setClassWanted(null)
    }
  }, [dateIsWanted])


  const onSubmitPayment = () => {
    personalData.specialRequests.push(otherRequest)


    const prePaymentPostData = {
      courseId: course?.id,
      source: "Muerdete",
      quantity: quantity,
      unitPrice: 35,
      totalPrice: totalToPay,
      couponId: couponId ? couponId : null,
      buyer: {
        name: personalData?.name,
        email: personalData?.email,
        phone: personalData?.phone,
        birthdate: personalData?.birthDate,
        specialRequests: personalData?.specialRequests
      },
      lessonId: (classWanted && classWanted.id),
      isGift: isAGift,
      giftDetails: isAGift ? giftData : null
    };
    axios.post(checkoutURL, prePaymentPostData)
      .then(async (response) => {
        const { sessionId, url } = response.data;
        localStorage.setItem('stripeSessionId', sessionId);
        window.location.href = url;
        // Navegar a la nueva URL
      })
      .catch((error) => {
        console.error(error);
      });




    const purchaseData = {
      source: "Muerdete",
      courseId: course?.id,
      seatsQty: quantity,
      name: personalData?.name,
      email: personalData?.email,
      phone: personalData?.phone,
      birthDate: personalData?.birthDate,
      totalPrice: totalToPay,
      isGift: isAGift,
      giftDetails: isAGift ? giftData : null
    };

    updateData(purchaseData);
    setOtherRequest('')
    localStorage.setItem('purchaseData', JSON.stringify(purchaseData));

  };

  const handleOtherRequestChange = (value) => {
    setOtherRequest(value);
  };

  const handleSelectChange = (value) => {
    setQuantity(value);
  };

  const disabledReason = () => {
    return (
      (isAGift && !giftData.emailTo) ||
      (isAGift && !giftData.from) ||
      (isAGift && !giftData.message) ||
      (isAGift && !giftData.toName) ||
      (dateIsWanted && !classWanted) ||
      !personalData.name ||
      !personalData.email ||
      !personalData.birthDate ||
      !personalData.phone ||
      quantity > classWanted?.seats
    );
  };



  return (
    <div className="container my-5">
      <h2 className="mb-5">Resumen de compra</h2>
      <div className="row">
        <div className="col-12 col-lg-7">
          <ProductCartItem
            course={course}
            classWanted={classWanted}
            otherRequest={otherRequest}
            quantity={quantity}
            handleSelectChange={handleSelectChange}
            handleOtherRequestChange={handleOtherRequestChange}
            color={course?.color}
            size={course?.size}
            price={course?.price}
            personalData={personalData}
            setPersonalData={(personalData) => setPersonalData(personalData)}
            setGiftData={(giftData) => setGiftData(giftData)}
            giftData={giftData}
            setIsAGift={(isAGift) => setIsAGift(isAGift)}
            isAGift={isAGift}
            setDateIsWanted={(dateIsWanted) => setDateIsWanted(dateIsWanted)}
          />
        </div>
        <div className="col-12 col-lg-5 mt-4 mt-lg-0">
          <div className="card shadow-xs border bg-gray-100">
            <div className="card-body p-lg-4">
              <h5>{course?.name}</h5>
              <OrderSummary
                totalToPay={totalToPay}
                quantity={quantity}
                classSelected={!!classWanted && classWanted}
                setTotalToPay={setTotalToPay}
                setCouponId={setCouponId}
              />
              <button
                disabled={disabledReason()}
                onClick={onSubmitPayment}

                className="btn btn-primary btn-lg w-100 mb-0"
              >
                Continuar con el pago
              </button>
            </div>
          </div>
          {(dateIsWanted) &&
            <DateSelectionWithoutVoucher
              setClassSelected={(classWanted) =>
                setClassWanted(classWanted)
              }
              course={course}
              quantity={quantity}
            />
          }
        </div>
      </div>
    </div>
  );
}
