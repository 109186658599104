import { useEffect, useRef, useState } from "react";
import {
  CopyOutlined,
  DeleteOutlined,
  EditOutlined,
  EyeFilled,
  MailOutlined,
  PlusOutlined,
  SearchOutlined,
  StopOutlined,
  UserDeleteOutlined,
} from "@ant-design/icons";
import { Button, Input, notification, Space, Table, Popconfirm, Tooltip, Modal, Form } from "antd";
import Highlighter from "react-highlight-words";
import { useGetVouchers } from "../../../hooks/data/use-crud-vouchers";
import useGetLessons from "../../../hooks/data/use-get-lessons";
import useGetClients from "../../../hooks/data/use-get-clients";
import { Link, useNavigate } from "react-router-dom";
import DateSelector from "./datePicker";
import dayjs from "dayjs";
import moment from "moment";
import axios from "axios";
import { specialRequestLiteralObject } from "../../../utils/constants";
import './table.css'


const LessonsTable = ({ selectedDate, setSelectedDate }) => {

  const [api, contextHolder] = notification.useNotification();



  const openNotification = (message, description, placement) => {
    api[message.type]({
      message: message.content,
      description,
      placement,
    });
  };


  const today = dayjs().format("YYYY-MM-DD");
  const vouchers = useGetVouchers();
  const clients = useGetClients()
  const lessons = useGetLessons();
  const navigate = useNavigate();
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);
  const [lessonsData, setLessonsData] = useState();
  const [dateSelectedLessons, setDateSelectedLessons] = useState();
  const [emailToSend, setEmailToSend] = useState('');
  const [studentsPerLesson, setStudentsPerLesson] = useState();
  const [tableData, setTableData] = useState();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isModalMailVisible, setIsModalMailVisible] = useState(false);
  const [seatsAdd, setSeatsAdd] = useState();
  const [lessonIdToUpdate, setLessonIdToUpdate] = useState('');
  const [studentToSendEmail, setStudentToSendEmail] = useState()


  useEffect(() => {
    localStorage.setItem('activeTabKey', '2');

    const savedSelectedDate = localStorage.getItem('selectedDate');
    if (savedSelectedDate) {
      setSelectedDate(savedSelectedDate);
    }
  }, []);

  useEffect(() => {
    localStorage.setItem('selectedDate', selectedDate);
  }, [selectedDate]);

  useEffect(() => {
    setStudentsPerLesson(
      lessonsData?.map((studentsPerLesson) => {
        const studentsArray = studentsPerLesson?.[0];
        const lessonsId = studentsPerLesson?.[1];
        const { students: studentId } = studentsArray;
        return studentId.map((id) => {
          const client = clients?.find((client) => client?.id === id);
          return {
            id: client?.id,
            name: client?.name,
            birthdate: moment(client?.birthdate).format("YYYY-MM-DD"),
            alreadyBilled: client?.alreadyBilled,
            email: client?.email,
            phone: client?.phone,
            specialRequests: client?.specialRequests,
            seatsQty: vouchers?.find(
              (voucher) => voucher.id === client?.voucherId
            )?.seatsQty,
            source: vouchers?.find(
              (voucher) => voucher.id === client?.voucherId
            )?.source,
            courseId: vouchers?.find(
              (voucher) => voucher.id === client?.voucherId
            )?.courseId,
            voucherId: vouchers?.find(
              (voucher) => voucher.id === client?.voucherId
            )?.id,
            lessonId: lessonsId?.id,
            courseTitle: findLessonTitle(lessonsId?.id),
            confirmationText: findConfirmationText(lessonsId.id),
            lessonTime: findLessonTime(lessonsId?.id),
            tracker: vouchers?.find(
              (voucher) => voucher.id === client?.voucherId
            )?.tracker,
            code: vouchers?.find(
              (voucher) => voucher.id === client?.voucherId
            )?.code,
          };
        });
      })
    );
  }, [lessonsData, clients]);


  const lessonIdsWithEmptyStudents = lessonsData
    ?.filter((item) => item?.[0].students.length === 0)
    ?.map((item) => item?.[1].id);

  useEffect(() => {
    setLessonsData(
      dateSelectedLessons?.map(({ students, id }) => [
        { students: students },
        { id: id },
      ])
    );
  }, [dateSelectedLessons]);

  const closeModal = () => {
    setIsModalVisible(false);
  };

  const closeMailModal = () => {
    setIsModalMailVisible(false);
    setEmailToSend('');
  };

  const calculateOccupiedSeats = (students) => {
    if (!students || students.length === 0) return 0;

    return students.reduce((total, student) => {
      return total + (student.seatsQty || 1);
    }, 0);
  };



  useEffect(() => {
    const nonEmptyArrays = [];
    for (const key in studentsPerLesson) {
      if (studentsPerLesson.hasOwnProperty(key)) {
        if (studentsPerLesson[key]?.length > 0) {
          nonEmptyArrays[key] = studentsPerLesson[key];
        }
      }
    }
    setTableData(nonEmptyArrays);
  }, [studentsPerLesson]);

  function findLessonTitle(id) {
    const lesson = lessons?.find((lesson) => lesson.id === id);
    if (lesson) {
      return lesson?.course.title;
    }
  }

  function findConfirmationText(id) {
    const lesson = lessons?.find((lesson) => lesson.id === id);
    if (lesson) {
      return lesson?.course.confirmationText;
    }
  }


  function findLessonTime(id) {
    const lesson = lessons?.find((lesson) => lesson.id === id);
    if (lesson) {
      return [lesson?.startHour, lesson?.finishHour];
    }
  }

  function findLessonSeatsAvailable(id) {
    const lesson = lessons?.find((lesson) => lesson.id === id);
    console.log("esta es la leccion", lesson)
    if (lesson) {
      return [lesson?.seats];
    }
  }

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, close }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Buscar ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Buscar
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            Cerrar
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1677ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const handleDelete = (record) => {
    axios
      .delete(
        process.env.REACT_APP_URI +
        `/v1/lessons/${record.lessonId}/client/${record.id}/voucher/${record.voucherId}`
      )
      .then(
        api.success({
          message: `Alumno eliminado`,
          description: `El alumno ${record.name} ha sido eliminado de este taller con éxito.`,
          placement: "bottomRight",
        }),
        setTableData(
          tableData?.map((innerArray) =>
            innerArray.filter((obj) => obj.id !== record.id)
          )
        )
      );
  };

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text)
      .then(() => {
        console.log('Texto copiado con éxito');
      })
      .catch(err => {
        console.error('Error al copiar texto: ', err);
      });
  };

  const openModal = (lessonIdToChange) => {
    setLessonIdToUpdate(lessonIdToChange)
    setIsModalVisible(true)
  }

  const handleBill = async (record) => {
    const updateClientDto = {
      alreadyBilled: !record.alreadyBilled
    }
    try {
      await axios.patch(process.env.REACT_APP_URI + `/v1/clients/${record.id}`, updateClientDto);
      setTableData((prevTableData) => {
        return prevTableData.map((innerArray) =>
          innerArray.map((obj) =>
            obj.id === record.id
              ? { ...obj, alreadyBilled: !obj.alreadyBilled }
              : obj
          )
        );
      });
    } catch (error) {
      console.log("error", error)
    }

  }

  const handleAssist = async (record) => {
    const updateClientDto = {
      wasPresent: !record.wasPresent
    }
    try {
      await axios.patch(process.env.REACT_APP_URI + `/v1/clients/${record.id}`, updateClientDto);
      setTableData((prevTableData) => {
        return prevTableData.map((innerArray) =>
          innerArray.map((obj) =>
            obj.id === record.id
              ? { ...obj, wasPresent: !obj.wasPresent }
              : obj
          )
        );
      });
    } catch (error) {
      console.log("error", error)
    }

  }

  const handleSendEmail = async () => {
    const formatedDate = dayjs(selectedDate).format("DD-MM-YYYY")
    const emailDto = {
      to: emailToSend,
      name: studentToSendEmail.name,
      course: studentToSendEmail.courseTitle,
      date: formatedDate,
      startHs: studentToSendEmail.lessonTime[0],
      finishHs: studentToSendEmail.lessonTime[1],
      confirmationText: studentToSendEmail.confirmationText,
      quantity: studentToSendEmail.seatsQty
    }

    try {
      const url = process.env.REACT_APP_URI + `/v1/email`
      await axios.post(url, emailDto)
      openNotification(
        { type: "success", content: "Email Enviado" },
        "El email se ha reenviado satisfactoriamente.",
        "bottomRight"
      );
      setTimeout(() => setIsModalMailVisible(false));
    } catch (error) {
      openNotification(
        { type: "error", content: "Fallo envio email" },
        "El email no se ha podido enviar correctamente.",
        "bottomRight"
      );
      setTimeout(() => setIsModalMailVisible(false));
    }
    setEmailToSend('');

  }




  // ...existing code...

  // ...existing code...

  const columns = [
    {
      title: "",
      dataIndex: "wasPresent",
      key: "wasPresent",
      width: "40px",
      fixed: "left",
      render: (_, record) => (
        <Button
          type="danger"
          className={`btn-sm assist-button ${record.wasPresent ? "bg-primary" : "bg-secondary"}`}
          onClick={() => handleAssist(record)}
        >
          <UserDeleteOutlined />
        </Button>
      ),
    },
    {
      title: "#",
      dataIndex: "seatsQty",
      key: "seatsQty",
      width: "30px",
    },
    {
      title: "Alumno",
      dataIndex: "name",
      key: "name",
      width: "25%",
      ellipsis: true,
      ...getColumnSearchProps("name"),
      render: (_, record) => (
        <div className="d-flex gap-1 align-items-center">
          <div className="text-truncate">{record.name}</div>
          {record.specialRequests && record.specialRequests.length > 0 ? (
            <Tooltip
              title={record.specialRequests.map((el) => (
                <p key={el.id} className="m-0">{specialRequestLiteralObject[el] || el}</p>
              ))}
            >
              <EyeFilled style={{ cursor: "pointer" }} />
            </Tooltip>
          ) : null}
        </div>
      ),
    },
    {
      title: "Teléfono",
      dataIndex: "phone",
      key: "phone",
      width: "20%",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      width: "25%",
      ellipsis: true,
      ...getColumnSearchProps("email"),
    },
    {
      title: "Origen",
      dataIndex: "source",
      key: "source",
      width: "10%",
      className: "d-none d-lg-table-cell",
      ...getColumnSearchProps("source"),
    },
    {
      title: "Cumpleaños",
      dataIndex: "birthdate",
      key: "birthdate",
      width: "10%",
      className: "d-none d-lg-table-cell",
    },
    {
      title: "Localizador",
      dataIndex: "tracker",
      key: "tracker",
      width: "10%",
      className: "d-none d-lg-table-cell",
      render: (text) => <span onClick={() => copyToClipboard(text)}>{text}</span>,
    },
    {
      title: "Código",
      dataIndex: "code",
      key: "code",
      width: "10%",
      className: "d-none d-lg-table-cell",
      render: (text) => <span onClick={() => copyToClipboard(text)}>{text}</span>,
    },
    {
      title: "Acciones",
      dataIndex: "actions",
      width: "10%",
      className: "d-none d-md-table-cell",
      render: (_, record) => (
        <div className="table-actions-container">
          <Popconfirm
            title="Eliminar alumno"
            description="¿Está seguro de que desea eliminar este alumno de este taller?"
            onConfirm={() => handleDelete(record)}
            okText="Si"
            cancelText="No"
          >
            <Button type="danger" className="btn-sm">
              <DeleteOutlined />
            </Button>
          </Popconfirm>

          <Button type="danger" className="btn-sm">
            <Link to={`/backoffice/editStudent/${record.id}`}>
              <EditOutlined />
            </Link>
          </Button>

          <Button
            type="danger"
            className={`btn-sm ${record.alreadyBilled ? "bg-success" : "bg-danger"}`}
            onClick={() => handleBill(record)}
          >
            <CopyOutlined />
          </Button>

          <Button
            type="danger"
            className="btn-sm"
            onClick={() => handleClicMailIcon(record)}
          >
            <MailOutlined />
          </Button>
        </div>
      ),
    },
  ];


  const handleClicMailIcon = (studentToSendEmailInformation) => {
    setStudentToSendEmail(studentToSendEmailInformation);
    setIsModalMailVisible(true);
  }

  const handleSeatsAdd = (e) => {
    setSeatsAdd(e.target.value)
  }

  const handleNewMail = (e) => {
    setEmailToSend(e.target.value)
  }

  const handleOkModal = async () => {
    try {
      const updateLessonDto = {
        seats: +(+findLessonSeatsAvailable(lessonIdToUpdate) + +seatsAdd)
      }
      const url = process.env.REACT_APP_URI + `/v1/lessons/${lessonIdToUpdate}`
      await axios.patch(url, updateLessonDto)
    } catch (error) {
      console.log("error updating", error)
    }
    window.location.reload()
    closeModal()
  }

  const handleOnClickAddStudent = (lessonId) => {
    localStorage.setItem('selectedDate', selectedDate)
    navigate(`/backoffice/agregarAlumno/${lessonId}`);
  };

  const handleClickEditLesson = (lessonId) => {
    localStorage.setItem('selectedDate', selectedDate)
    navigate(`/backoffice/editar/leccion/${lessonId}`);
  };


  return (
    <>

      {contextHolder}
      <div className="d-flex justify-content-between mt-4 mb-3">
        <h4>
          Talleres
          {moment(selectedDate).format("YYYY-MM-DD") === today
            ? " de hoy"
            : " del dia " + moment(selectedDate).format("YYYY-MM-DD")}
        </h4>
        <div className="d-flex gap-3 my-auto">
          <DateSelector
            setDateSelectedLessons={(dateSelectedLessons) =>
              setDateSelectedLessons(dateSelectedLessons)
            }
            selectedDate={selectedDate}
            setSelectedDate={(selectedDate) => setSelectedDate(selectedDate)}
          />
          <Link to={`/backoffice/agregar-taller`}>
            <button className="btn btn-primary btn-sm m-0">
              Añadir fecha de taller
            </button>
          </Link>
          <Link to={`/backoffice/agregar-reserva`}>
            <button className="btn bg-purple text-white btn-sm m-0">
              Crear reserva a taller
            </button>
          </Link>
        </div>
      </div>
      {tableData?.length
        ? Object.values(tableData)?.map((obj, index) => {
          // Calcular el total de plazas ocupadas
          const occupiedSeats = calculateOccupiedSeats(obj);
          const availableSeats = findLessonSeatsAvailable(obj?.[0]?.lessonId);

          return (
            <div className="card border my-3" key={index}>
              <div className="d-flex flex-column flex-md-row justify-content-between border-bottom p-2 p-lg-3">
                <h6 className="mb-2 mb-md-0">
                  <div className="d-flex flex-column flex-md-row justify-content-between gap-2 gap-md-5">
                    <div>
                      {obj?.[0]?.courseTitle} {obj?.[0]?.lessonTime?.[0]}-
                      {obj?.[0]?.lessonTime?.[1]}
                    </div>

                    <div className="lesson-stats">
                      <div className="d-flex gap-2">
                        <span>Plazas ocupadas:</span>
                        <strong>{occupiedSeats}</strong>
                      </div>
                      <div className="d-flex gap-2">
                        <span>Plazas disponibles:</span>
                        <strong>{availableSeats}</strong>
                      </div>
                    </div>
                  </div>
                </h6>
                <div className="d-flex flex-wrap gap-2">
                  <div className="button-container">
                    <div onClick={() => openModal(obj?.[0]?.lessonId)} className="h-100 btn btn-warning btn-sm m-0 d-flex align-items-center gap-1">
                      <PlusOutlined className='cursor-pointer' />
                      <span className="d-none d-sm-inline">Agregar plazas</span>
                    </div>
                  </div>
                  <div className="button-container">
                    {
                      findLessonSeatsAvailable(obj?.[0]?.lessonId) > 0 &&
                      <Link to={`/backoffice/agregarAlumno/${obj?.[0]?.lessonId}`}>
                        <div className="btn btn-primary btn-sm m-0 d-flex align-items-center gap-1 h-100">
                          <PlusOutlined /> <span className="d-none d-sm-inline">Agregar Alumnos</span>
                        </div>
                      </Link>
                    }
                  </div>
                  <div className="button-container">
                    <Link to={`/backoffice/editar/leccion/${obj?.[0]?.lessonId}`}>
                      <div className="btn btn-primary btn-sm m-0 d-flex align-items-center gap-1 h-100">
                        <EditOutlined /> <span className="d-none d-sm-inline">Editar</span>
                      </div>
                    </Link>
                  </div>
                  <div className="button-container">
                    <Popconfirm
                      title="Eliminar este taller"
                      description="¿Está seguro de que desea eliminar este taller? PERDERAS LA INFORMACIÓN SI YA HAY ALUMNOS INSCRIPTOS"
                      onConfirm={() =>
                        axios
                          .delete(
                            process.env.REACT_APP_URI +
                            `/v1/lessons/${obj?.[0]?.lessonId}`
                          )
                          .then(
                            api.success({
                              message: `Taller deshabilitado`,
                              description: `El taller ${obj?.[0]?.courseTitle} ha sido deshabilitada con éxito.`,
                              placement: "bottomRight",
                            })
                          )
                      }
                      okText="Si"
                      cancelText="No"
                    >
                      <button className="h-100 btn btn-secondary btn-sm m-0 d-flex align-items-center gap-1">
                        <StopOutlined /> <span className="d-none d-sm-inline">Eliminar</span>
                      </button>
                    </Popconfirm>
                  </div>
                </div>
              </div>
              <div className="card-body">
                <Table
                  className='ant-table'
                  columns={columns}
                  dataSource={obj}
                  pagination={{
                    pageSize: 30,
                    size: "small",
                    hideOnSinglePage: true
                  }}
                  scroll={{ x: 'max-content' }}
                  size="small"
                />
              </div>
            </div>
          );
        })
        : "No hay talleres en la fecha seleccionada"}


      {lessonIdsWithEmptyStudents?.length > 0 &&
        lessonIdsWithEmptyStudents?.map((lesson) => (
          <div className="card border p-lg-3 my-3" key={lesson.id}>
            <div className="d-flex justify-content-between">
              <p className="text-bold">
                {findLessonTitle(lesson)} {findLessonTime(lesson)?.[0]}-
                {findLessonTime(lesson)?.[1]}
              </p>
              <div className='d-flex gap-4'>
                <button
                  onClick={() => handleClickEditLesson(lesson)} className="btn btn-primary btn-sm m-0 px-1.25">
                  <EditOutlined /> Editar Taller
                </button>
                {/* <Link className='btn-sm' to={`/backoffice/agregarAlumno/${lesson.id}`}> */}
                <button onClick={() => handleOnClickAddStudent(lesson)} className="btn btn-primary btn-sm m-0 px-1.25">
                  <PlusOutlined /> Agregar Alumnos
                </button>
                {/* </Link> */}
                <Popconfirm
                  title="Eliminar este taller"
                  description="¿Está seguro de que desea eliminar este taller? PERDERAS TODA LA INFORMACIÓN SI HAY INSCRIPTOS"
                  onConfirm={() =>
                    axios
                      .delete(process.env.REACT_APP_URI + `/v1/lessons/${lesson}`)
                      .then(
                        api.success({
                          message: `Taller deshabilitado`,
                          description: `El taller ${findLessonTitle(
                            lesson
                          )} ha sido deshabilitado con éxito.`,
                          placement: "bottomRight",
                        })
                      )
                  }
                  okText="Si"
                  cancelText="No"
                >
                  <button className="btn btn-secondary btn-sm m-0">
                    <StopOutlined /> Eliminar este taller
                  </button>
                </Popconfirm>
              </div>
            </div>
            <p className="text-gray m-0">Todavia no hay alumnos inscriptos</p>
          </div>
        ))}
      <Modal
        open={isModalVisible}
        onOk={handleOkModal}
        onCancel={closeModal}
        className='modal-dialog-centered'
      >
        <div className='modal-content'>
          <div className='modal-header'>
            <span className='modal-title'>Ingrese las plazas a añadir</span>
          </div>
          <div className='modal-body'>
            <Input
              className='m-1 w-75 mx-auto'
              size='middle'
              type='number'
              name='seats'
              value={seatsAdd}
              onChange={(e) => handleSeatsAdd(e)}
            />
          </div>
        </div>
      </Modal>
      <Modal
        afterClose={() => setEmailToSend('')}
        open={isModalMailVisible}
        onOk={() => handleSendEmail()}
        onCancel={closeMailModal}
        width={1200}
        className='modal-dialog-centered d-flex justify-content-center align-items-center'
      >
        <Input
          className='m-1 w-100 mx-auto m-4'
          size='large'
          placeholder="Ingrese el mail de destino"
          type='email'
          name='email'
          value={emailToSend}
          onChange={(e) => handleNewMail(e)}
        />
      </Modal>

    </>
  );
};
export default LessonsTable;
