import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import backgroundImage from "../../assets/images/hero/portadaHome.jpg";

export const PromoSectionLarge = ({
  title,
  talleresRef,
  fullDescription,
  firstButtonTitle,
  secondButtonTitle,
}) => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const styles = {
    pageHeader: {
      backgroundImage: `url(${backgroundImage})`,
      // Posicionar la imagen para mostrar la parte derecha (mujer comiendo pasta)
      backgroundPosition: isMobile ? "80% center" : "right center",
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      width: "100%",
      height: "100vh",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      position: "relative",
      textAlign: "center",
      padding: "0 20px",
    },
    overlay: {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      backgroundColor: isMobile ? "rgba(0, 0, 0, 0.65)" : "rgba(0, 0, 0, 0.45)",
    },
    contentContainer: {
      position: "relative",
      zIndex: 10,
      maxWidth: "90%",
      width: isMobile ? "100%" : "800px",
    },
    title: {
      fontSize: isMobile ? "2rem" : "3.2rem",
      fontWeight: "bold",
      marginBottom: "20px",
      color: "#ffffff",
      textShadow: "2px 2px 4px rgba(0, 0, 0, 0.7)",
    },
    description: {
      fontSize: isMobile ? "1rem" : "1.5rem",
      color: "#ffffff",
      marginBottom: "30px",
      textShadow: "1px 1px 3px rgba(0, 0, 0, 0.8)",
      fontWeight: "500",
    },
    buttons: {
      display: "flex",
      flexDirection: isMobile ? "column" : "row",
      gap: "15px",
      marginBottom: "30px",
      justifyContent: "center",
    },
    button: {
      padding: isMobile ? "12px 20px" : "14px 25px",
      fontSize: isMobile ? "1rem" : "1.1rem",
      fontWeight: "bold",
      borderRadius: "8px",
      border: "none",
      cursor: "pointer",
      transition: "0.3s",
      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
    },
    bonusTitle: {
      fontSize: isMobile ? "1.3em" : "1.9em",
      fontWeight: "bold",
      color: "#ffffff",
      marginBottom: "5px",
      textShadow: "2px 2px 4px rgba(0, 0, 0, 0.7)",
    },
    price: {
      fontSize: isMobile ? "2em" : "2.8em",
      fontWeight: "bold",
      color: "#FF0000",
      marginBottom: "0",
      textShadow: "2px 2px 4px rgba(0, 0, 0, 0.7)",
    }
  };

  const scrollToTalleres = () => {
    if (talleresRef && talleresRef.current) {
      talleresRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <section style={styles.pageHeader}>
      {/* Overlay oscuro para mejorar legibilidad del texto */}
      <div style={styles.overlay}></div>

      {/* Contenido Principal directamente sobre la imagen */}
      <motion.div
        style={styles.contentContainer}
        initial={{ opacity: 0, y: 30 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.7, delay: 0.3 }}
      >
        {/* Título y descripción */}
        <motion.h1
          style={styles.title}
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.7, delay: 0.5 }}
        >
          {title}
        </motion.h1>

        <motion.p
          style={styles.description}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.7, delay: 0.7 }}
        >
          {fullDescription}
        </motion.p>

        {/* Botones */}
        <motion.div
          style={styles.buttons}
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.7, delay: 0.9 }}
        >
          <motion.button
            style={{ ...styles.button, backgroundColor: "#FF4081", color: "#fff" }}
            whileHover={{ scale: 1.05, boxShadow: "0 6px 12px rgba(0, 0, 0, 0.3)" }}
            whileTap={{ scale: 0.95 }}
            onClick={scrollToTalleres}
          >
            {secondButtonTitle}
          </motion.button>

          <motion.button
            style={{ ...styles.button, backgroundColor: "#FFC107", color: "#333" }}
            whileHover={{ scale: 1.05, boxShadow: "0 6px 12px rgba(0, 0, 0, 0.3)" }}
            whileTap={{ scale: 0.95 }}
            onClick={() => (window.location.href = "/reserva")}
          >
            {firstButtonTitle}
          </motion.button>

          <motion.button
            style={{ ...styles.button, backgroundColor: "#00C853", color: "#fff" }}
            whileHover={{ scale: 1.05, boxShadow: "0 6px 12px rgba(0, 0, 0, 0.3)" }}
            whileTap={{ scale: 0.95 }}
            onClick={scrollToTalleres}
          >
            Ver Calendario
          </motion.button>
        </motion.div>

        {/* Sección del bono directamente sobre la imagen */}
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.7, delay: 1.1 }}
        >
          <h2 style={styles.bonusTitle}>BONO PARA 2 PERSONAS</h2>
          <p style={styles.price}>65 €</p>
        </motion.div>
      </motion.div>
    </section>
  );
};

export default PromoSectionLarge;