import React, { useEffect, useState } from 'react';
import { Table, TableBody, TableCell, TableColumn, TableHeader, TableRow } from '@nextui-org/table';
import axios from 'axios';
import { Button, Input, Modal, Pagination, Spin, notification } from 'antd';
import dayjs from 'dayjs';
import { EditOutlined, MailOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';

const ClientSearcher = ({ setActiveTabKey, setSelectedDate }) => {
    const [clientsFromDb, setClientsFromDb] = useState([]);
    const [loading, setLoading] = useState(true);
    const [searchTerm, setSearchTerm] = useState('');
    const [searchResults, setSearchResults] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [emailToSend, setEmailToSend] = useState('');
    const [studentToSendEmail, setStudentToSendEmail] = useState();
    const [isModalMailVisible, setIsModalMailVisible] = useState(false);


    const [api, contextHolder] = notification.useNotification();


    const openNotification = (message, description, placement) => {
        api[message.type]({
            message: message.content,
            description,
            placement,
        });
    };

    const closeMailModal = () => {
        setIsModalMailVisible(false);
        setEmailToSend('');
    };


    const handleNewMail = (e) => {
        setEmailToSend(e.target.value)
    }


    const handleSendEmail = async () => {
        const emailDto = {
            to: emailToSend,
            name: studentToSendEmail.name,
            course: studentToSendEmail.course?.title,
            date: formatDate(studentToSendEmail.lessons[0].lessonDate),
            startHs: studentToSendEmail.lessons[0].startHour,
            finishHs: studentToSendEmail.lessons[0].finishHour,
            confirmationText: studentToSendEmail.course.confirmationText,
            quantity: studentToSendEmail.vouchers.seatsQty.toString()
        }


        try {
            const url = process.env.REACT_APP_URI + `/v1/email`
            await axios.post(url, emailDto)
            openNotification(
                { type: "success", content: "Email Enviado" },
                "El email se ha reenviado satisfactoriamente.",
                "bottomRight"
            );
            setEmailToSend('');
            setTimeout(() => setIsModalMailVisible(false), 1700);
        } catch (error) {
            openNotification(
                { type: "error", content: "Fallo envio email" },
                "El email no se ha podido enviar correctamente.",
                "bottomRight"
            );
            setTimeout(() => setIsModalMailVisible(false), 1700);
        }
        setEmailToSend('');

    }


    const getClientsFromDb = async () => {
        try {
            const clientsRequest = await axios.get(process.env.REACT_APP_URI + `/v1/clients/withVouchers`);
            const filteredClients = clientsRequest.data.filter((client) => client.lessons.length > 0)
            setClientsFromDb(filteredClients);
            setLoading(false);
        } catch (error) {
            console.log("error fetching", error);
        }
    };

    useEffect(() => {
        getClientsFromDb();
    }, []);

    const headersToRender = [
        {
            title: 'Nombre',
            accessor: 'name',
        },
        {
            title: 'Email',
            accessor: 'email',
        },
        {
            title: 'Telefono',
            accessor: 'phone',
        },
        {
            title: 'Asientos',
            accessor: 'seats',
        },
        {
            title: 'Origen',
            accessor: 'source',
        },
        {
            title: 'Localizador',
            accessor: 'localizador',
        },
        {
            title: 'Código',
            accessor: 'code',
        },
        {
            title: 'Curso',
            accessor: 'course',
        },
        {
            title: 'Horario',
            accessor: 'time',
        },
        {
            title: '',
            accessor: 'buttons',
        },

    ];


    const handleClicMailIcon = (studentToSendEmailInformation) => {
        setStudentToSendEmail(studentToSendEmailInformation);
        setIsModalMailVisible(true);
    }


    useEffect(() => {
        const filteredClients = clientsFromDb?.filter(client => {
            // Normalizar el término de búsqueda para teléfonos
            const normalizedSearchTerm = normalizePhoneNumber(searchTerm);

            return (
                client.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
                client.email.toLowerCase().includes(searchTerm.toLowerCase()) ||
                normalizePhoneNumber(client.phone).includes(normalizedSearchTerm) ||
                (client.vouchers?.code?.toLowerCase().includes(searchTerm.toLowerCase())) ||
                (client.vouchers?.source?.toLowerCase().includes(searchTerm.toLowerCase())) ||
                (client.vouchers?.tracker?.toLowerCase().includes(searchTerm.toLowerCase())) ||
                (client.course?.title?.toLowerCase().includes(searchTerm.toLowerCase()))
            );
        });
        setSearchResults(filteredClients);
    }, [searchTerm, clientsFromDb]);


    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
    };

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const handleClickLessonTime = (dateWanted) => {
        setSelectedDate(dateWanted);
        setActiveTabKey('2');
        localStorage.setItem('activeTabKey', '2')
    }

    const indexOfLastClient = currentPage * pageSize;
    const indexOfFirstClient = indexOfLastClient - pageSize;
    const currentClients = searchResults.slice(indexOfFirstClient, indexOfLastClient);

    const items = currentClients.map(item => {
        const voucher = item.vouchers ? item.vouchers : null;
        const seats = voucher ? voucher.seatsQty : '';
        const source = voucher ? voucher.source : '';
        const tracker = voucher ? voucher.tracker : '';
        const code = voucher ? voucher.code : '';

        return {
            key: item.id,
            name: <b>{item.name}</b>,
            email: <span>{item.email}</span>,
            phone: <span>{item.phone}</span>,
            seats: <span>{seats}</span>,
            source: <span>{source}</span>,
            localizador: <span>{tracker}</span>,
            code: <span>{code}</span>,
            course: <span>{item.course?.title}</span>,
            time: item.lessons[0]?.lessonDate && item.lessons[0]?.startHour ? (
                <span onClick={() => handleClickLessonTime(item.lessons[0]?.lessonDate)} className='text-blue-500 cursor-pointer'>
                    <u>{formatDate(item.lessons[0]?.lessonDate)} - {item.lessons[0]?.startHour}</u>
                </span>
            ) : (
                <span>No hay información</span>
            ), buttons: (
                <div className="d-flex ">
                    <Button type="danger" >
                        <Link to={`/backoffice/editStudent/${item.id && item.id}`}>
                            <EditOutlined />
                        </Link>
                    </Button>
                    <Button type='danger' onClick={() => handleClicMailIcon(item)} className={`d-flex justify-content-center align-items-center `} >
                        <MailOutlined />
                    </Button>
                </div>
            )
        };
    });

    function formatDate(dateString) {
        const date = new Date(dateString);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Los meses van de 0 a 11
        const year = date.getFullYear();

        return `${day}-${month}-${year}`;
    }

    if (loading) {
        return <Spin className='w-100 d-flex justify-content-center' size="large" />;
    }


    const normalizePhoneNumber = (phone) => {
        if (!phone) return '';
        return phone.toString().replace(/\s+|\+/g, '');
    };



    return (
        <div className='flex items-center justify-center'>
            <Input
                type="text"
                placeholder="Buscar..."
                value={searchTerm}
                onChange={handleSearchChange}
            />
            <Table isStriped className='w-full justify-center items-center'>
                <TableHeader>
                    {headersToRender.map((header, index) => (
                        <TableColumn className='bg-gray-500 rounded-full text-white' key={index}>{header.title}</TableColumn>
                    ))}
                </TableHeader>
                <TableBody emptyContent={'No hay información'}>
                    {items.map((client, index) => (
                        <TableRow key={`entity-${index}`} className={`border-b'}`}>
                            {headersToRender.map((header, headerIndex) => (
                                <TableCell key={headerIndex}>{client[header.accessor]}</TableCell>
                            ))}
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
            <div className='w-full justify-center items-center bg-green-300'>
                <Pagination
                    total={searchResults.length}
                    showTotal={(total) => `Total ${total} clientes`}
                    pageSize={pageSize}
                    current={currentPage}
                    onChange={handlePageChange}
                />
            </div>
            <Modal
                afterClose={() => setEmailToSend('')}
                open={isModalMailVisible}
                onOk={() => handleSendEmail()}
                onCancel={closeMailModal}
                width={1200}
                className='modal-dialog-centered d-flex justify-content-center align-items-center'
            >
                <Input
                    className='m-1 w-100 mx-auto m-4'
                    size='large'
                    placeholder="Ingrese el mail de destino"
                    type='email'
                    name='email'
                    value={emailToSend}
                    onChange={(e) => handleNewMail(e)}
                />
            </Modal>

        </div>
    );
};

export default ClientSearcher;
