import { useEffect, useState } from "react";
import moment from "moment";
import "react-day-picker/dist/style.css";
import { DayPicker } from "react-day-picker";
import { Modal, Spin } from "antd";
import {
  ClockCircleOutlined,
  TeamOutlined,
  WarningOutlined,
  LoadingOutlined
} from "@ant-design/icons";
import useGetLessons from "../../../hooks/data/use-get-lessons";
import { es } from 'date-fns/locale';
import { useLesson } from "../../../context/Provider";
import { useNavigate } from "react-router-dom";

const LessonCalendarForClients = ({
  course,
}) => {
  const lessons = useGetLessons();
  const [classes, setClasses] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalData, setModalData] = useState();
  const [daySelected, setDaySelected] = useState();
  const [daysAvailable, setDaysAvailable] = useState([]);
  const [selectedTallerId, setSelectedTallerId] = useState(null);
  const [loading, setLoading] = useState(true);
  const quantity = 1;
  const navigate = useNavigate()

  const { selectedLesson, setSelectedLesson } = useLesson();

  useEffect(() => {
    setLoading(true);
    if (lessons) {
      setClasses(lessons.filter((lesson) => lesson?.courseId === course?.id));
      setLoading(false);
    }
  }, [lessons, course]);

  useEffect(() => {
    setLoading(true);
    const filteredDates = classes
      ?.filter((cl) => {
        const lessonDate = new Date(cl?.lessonDate);
        const currentDate = new Date();
        const endDate = new Date();
        endDate.setDate(currentDate.getDate() + 180);

        return lessonDate >= currentDate && lessonDate <= endDate;
      })
      .map((cl) => new Date(cl?.lessonDate));

    setDaysAvailable(filteredDates);
    setLoading(false);
  }, [classes]);

  const formatDate = (date) => {
    return moment(date).format("YYYY-MM-DD");
  };

  const GetIsDayDisabled = (day) => {
    return (
      daysAvailable?.find(
        (availableDate) => formatDate(day) === formatDate(availableDate)
      ) === undefined
    );
  };

  const setClassDate = (e) => {
    setIsModalOpen(true);
    setDaySelected(formatDate(e));
    setModalData(
      classes?.filter((cl) => formatDate(cl?.lessonDate) === formatDate(e))
    );
    // Limpiar la selección previa
    setSelectedTallerId(null);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setSelectedTallerId(null);
  };

  const handleTallerSelection = (taller) => {
    setSelectedLesson(taller);
    setSelectedTallerId(taller.id);
  };

  const handleComprar = () => {
    if (!selectedTallerId) return;

    // Buscar el taller seleccionado
    const selectedTaller = modalData.find(data => data.id === selectedTallerId);
    if (!selectedTaller || !selectedTaller.course || !selectedTaller.course.title) return;

    setIsModalOpen(false);
    const courseTitle = selectedTaller.course.title.split(' ').join('-').toLowerCase();
    navigate(`${courseTitle}/compra`);
  };

  const handleTengoUnBono = () => {
    if (!selectedTallerId) return;

    setIsModalOpen(false);
    // Navegamos a la página de reserva con bonos
    navigate('/reserva#booking-form');
  };

  // Función para determinar la clase del botón según las plazas disponibles
  const getButtonClass = (seats) => {
    if (seats === 0) return 'btn-danger';
    if (seats === 1) return 'btn-warning'; // Naranja para 1 plaza
    return 'btn-success';                  // Verde para más de 1 plaza
  };

  // Icono personalizado para el spinner
  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

  return (
    <>
      <Modal
        title={`Horarios para el día ${daySelected}.`}
        open={isModalOpen}
        footer={false}
        onCancel={handleCancel}
      >
        {modalData && modalData.some(data =>
          data.seats > 0 &&
          data.seats >= quantity
        ) ? (
          <div>
            <div className="d-flex gap-4 py-4 flex-wrap">
              {modalData
                .map(data => (
                  <button
                    key={data.id}
                    onClick={() => handleTallerSelection(data)}
                    className={`w-100 btn shadow-xs border d-block text-center ${getButtonClass(data.seats)} ${selectedTallerId === data.id ? 'border-primary border-2' : ''}`}
                  >
                    <div className="d-flex gap-3 mb-2">
                      <ClockCircleOutlined className="my-auto" />
                      {data?.startHour}-{data?.finishHour}
                    </div>
                    <div className="d-flex gap-3">
                      <TeamOutlined className="my-auto" />
                      {data.seats} {data.seats === 1 ? 'Persona' : 'Personas'}
                    </div>

                    {/* Mensaje de advertencia para cuando hay solo 1 plaza */}
                    {data.seats === 1 && (
                      <div className="mt-2 alert alert-warning py-1 mb-0 d-flex align-items-center gap-2">
                        <WarningOutlined />
                        <small>Válido solo para 1 bono de 1 persona</small>
                      </div>
                    )}
                  </button>
                ))}
            </div>

            {/* Botones de acción (solo visibles cuando se ha seleccionado un taller) */}
            {selectedTallerId && (
              <div className="d-flex justify-content-center gap-3 mt-4">
                <button
                  onClick={handleTengoUnBono}
                  className="btn btn-primary"
                >
                  Tengo un Bono
                </button>
                <button
                  onClick={handleComprar}
                  className="btn btn-success"
                >
                  Comprar
                </button>
              </div>
            )}
          </div>
        ) : (
          <p>El taller disponible del día de la fecha no cuenta con capacidad disponible.</p>
        )}
      </Modal>
      <div
        className={"card w-100 p-4 mx-auto shadow-xs border bg-gray-100 col-lg-4"
        }
      >
        <div className="card shadow-xs border mt-2 bg-gray-100 flex align-items-center w-100" style={{ height: '100%' }}>
          <div className="card-body p-lg-4 position-relative">
            {loading ? (
              <div className="text-center py-5" style={{ minHeight: "300px", display: "flex", alignItems: "center", justifyContent: "center" }}>
                <Spin indicator={antIcon} tip="Cargando calendario..." />
              </div>
            ) : (
              <DayPicker
                locale={es}
                disabled={GetIsDayDisabled}
                mode="single"
                daySelected={daySelected}
                onSelect={setClassDate}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};
export default LessonCalendarForClients;