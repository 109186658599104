import { useEffect, useState } from "react";
import axios from "axios";

const useGetCourses = () => {
  const [courses, setCourses] = useState([]);

  useEffect(() => {
    axios.get(process.env.REACT_APP_URI + "/v1/courses").then((response) => {
      const sortedCourses = response.data.sort((a, b) => {
        if (a.title === "Taller de sushi") return -1; // Poner primero "Taller de sushi"
        if (b.title === "Taller de sushi") return 1;
        return 0; // Mantener el orden de los demás cursos
      });

      setCourses(sortedCourses);
    });
  }, []);

  return courses;
};

export default useGetCourses;
