import React, { useState, useEffect } from 'react';

import { Tabs } from "antd";
import CoursesCards from "./courses/cards";
import LessonsTable from "./lessons/table";
import LessonsCalendar from "./lessons/lessonCalendar";
import dayjs from 'dayjs';
import { useDate } from '../../context/Provider';
import ClientSearcher from './clientSearcher/ClientSearcher';
import CouponsManager from './cupones/cupones';
import Birthday from './birthday/birthday';



const BackOfficeTabs = () => {

  const [activeTabKey, setActiveTabKey] = useState("1");
  const { selectedDate, setSelectedDate } = useDate();


  useEffect(() => {
    const savedTabKey = localStorage.getItem('activeTabKey');
    if (savedTabKey) {
      setActiveTabKey(savedTabKey);
    }
  }, []);




  const items = [
    {
      key: "1",
      label: `Calendario`,
      children: <LessonsCalendar setSelectedLessonDate={setSelectedDate} setActiveTabKey={setActiveTabKey} />,
    },
    {
      key: "2",
      label: `Inscriptos y gestión`,
      children: <LessonsTable setSelectedDate={setSelectedDate} selectedDate={selectedDate} />,
    },

    {
      key: "3",
      label: `Productos (nuestros talleres)`,
      children: <CoursesCards />,
    },
    {
      key: "4",
      label: `Buscador Clientes`,
      children: <ClientSearcher setActiveTabKey={setActiveTabKey} setSelectedDate={setSelectedDate} />,
    },
    {
      key: "5",
      label: `Cupones`,
      children: <CouponsManager setActiveTabKey={setActiveTabKey} setSelectedDate={setSelectedDate} />,
    },
  ];



  const onTabChange = (key) => {
    setActiveTabKey(key);
    localStorage.setItem('activeTabKey', key);
  };

  return (
    <div className="m-5">
      <div className="card border">
        <Tabs
          className="p-lg-4"
          activeKey={activeTabKey}
          onChange={onTabChange}
          items={items}
        />
      </div>
    </div>
  );
};

export default BackOfficeTabs;
