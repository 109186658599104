import { useParams } from "react-router-dom";
import { S3_BUCKET_NAME } from "../../utils/constants";
import useGetCourses from "../../hooks/data/use-get-courses";
import { useState, useEffect } from "react";
import axios from "axios";
import { Input, Button, message } from "antd";

export default function OrderSummary({ textColor, classSelected, quantity, totalToPay, setTotalToPay, setCouponId }) {
  const [courseToBuy, setCourseToBuy] = useState();
  const [coupons, setCoupons] = useState([]); // Lista de cupones
  const [voucherCode, setVoucherCode] = useState(""); // Código ingresado
  const [discount, setDiscount] = useState(0); // Descuento aplicado
  const [isValidCoupon, setIsValidCoupon] = useState(false);

  const courses = useGetCourses();
  const courseId = useParams().courseId;

  useEffect(() => {
    getCourseFromTitle();
  }, [courseId]);

  useEffect(() => {
    getCourseFromTitle();
  }, [courses]);

  useEffect(() => {
    fetchCoupons();
  }, []);

  const fetchCoupons = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_URI}/v1/coupons`);
      setCoupons(response.data);
    } catch (error) {
      console.error("Error obteniendo cupones:", error);
      message.error("No se pudieron cargar los cupones.");
    }
  };

  const getCourseFromTitle = () => {
    const courseIdLowerCase = courseId.toLowerCase().replace(/-/g, " ");
    const courseFilteredArray = courses?.filter(
      (course) => course.title.toLowerCase().replace(/-/g, " ") === courseIdLowerCase
    );
    setCourseToBuy(courseFilteredArray && courseFilteredArray[0]);
  };

  const applyVoucher = () => {
    const foundCoupon = coupons.find((coupon) => coupon.code === voucherCode);

    if (!foundCoupon) {
      message.error("Cupón inválido.");
      setDiscount(0);
      setIsValidCoupon(false);
      return;
    }

    if (foundCoupon.remainingUses <= 0) {
      message.error("El cupón ya ha sido utilizado el máximo de veces.");
      setDiscount(0);
      setIsValidCoupon(false);
      return;
    }

    if (new Date(foundCoupon.expiresAt) < new Date()) {
      message.error("El cupón ha expirado.");
      setDiscount(0);
      setIsValidCoupon(false);
      return;
    }

    // Aplicar descuento y actualizar total a pagar
    setDiscount(foundCoupon.amount);
    setIsValidCoupon(true);
    setCouponId(foundCoupon.id);
    message.success(`Cupón aplicado: -€${foundCoupon.amount}`);
  };
  const cancelVoucher = () => {
    setVoucherCode("");
    setDiscount(0);
    setIsValidCoupon(false);
    setTotalToPay(originalPrice);
    setCouponId('');
    message.info("Cupón cancelado.");
  };
  let variant = textColor ? `text-${textColor}` : "";

  function formatDate(dateString) {
    const date = new Date(dateString);
    if (isNaN(date.getTime())) {
      return "Fecha inválida";
    }
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  }

  const originalPrice = quantity === 1 ? +courseToBuy?.price : +courseToBuy?.price2 * quantity;
  const finalPrice = Math.max(0, originalPrice - discount); // Asegurar que el precio no sea negativo

  useEffect(() => {
    setTotalToPay(Math.max(0, originalPrice - discount)); // Asegurar que el total no sea negativo
  }, [originalPrice, discount, setTotalToPay]);

  return (
    <>
      <ul className="list-unstyled">
        <li className="border-bottom py-3">
          <div className="d-flex gap-3">
            <img
              className="w-70-px h-70-px fit-cover rounded-3 shadow-xs border"
              src={`${S3_BUCKET_NAME}/${courseToBuy?.id}/${courseToBuy?.images[0]}`}
              alt="logo"
            />
            <div className="w-100">
              {courseToBuy && (
                <div className="text-2xl">
                  <b>{courseToBuy.title}</b>
                </div>
              )}
              {classSelected?.lessonDate && (
                <>
                  <div className="d-flex justify-content-between">
                    Fecha:
                    <h6 className="text-md">{formatDate(classSelected.lessonDate)}</h6>
                  </div>
                  <div className="d-flex justify-content-between">
                    Hora:
                    <h6 className="text-md">
                      {classSelected.startHour} - {classSelected.finishHour}
                    </h6>
                  </div>
                </>
              )}
            </div>
          </div>
        </li>
        <li className="mt-3">
          <div className="d-flex justify-content-between">
            <h5 className={variant}>Orden Total</h5>
            <h5 className={variant}>€{originalPrice.toFixed(2)}</h5>
          </div>

          {/* 📌 Ingreso de cupón */}
          <div className="mt-3">
            <Input
              placeholder="Código de cupón"
              value={voucherCode}
              onChange={(e) => setVoucherCode(e.target.value)}
              className="w-100"
              disabled={isValidCoupon} // Deshabilita el input si ya se aplicó un cupón
            />
            {!isValidCoupon ? (
              <Button type="primary" className="mt-2 w-100" onClick={applyVoucher}>
                Aplicar cupón
              </Button>
            ) : (
              <Button type="default" danger className="mt-2 w-100" onClick={cancelVoucher}>
                Cancelar cupón
              </Button>
            )}
          </div>

          {/* 📌 Descuento aplicado */}
          {isValidCoupon && (
            <div className="mt-3 text-success">
              <h6>Cupón aplicado: -€{discount.toFixed(2)}</h6>
            </div>
          )}

          {/* 📌 Precio final después del descuento */}
          <div className="d-flex justify-content-between mt-3">
            <h5 className={variant}>Total a Pagar</h5>
            <h5 className={variant}>€{finalPrice.toFixed(2)}</h5>
          </div>
        </li>
      </ul>
    </>
  );
}
