import React, { useEffect, useState } from "react";
import { FiMapPin, FiPhone, FiInstagram } from "react-icons/fi";
import useIsMobile from "../../hooks/responsive/use-is-mobile";
import "../../assets/scss/loading-dot.css";
import './contact.css'
import adsmurai from '../../assets/logos/adsmurai.png'
import aws from '../../assets/logos/aws.png'
import bbva from '../../assets/logos/bbva.png'
import loreal from '../../assets/logos/loreal.png'
import apple from '../../assets/logos/apple.png'
import mango from '../../assets/logos/mango.png'
import radiosants from '../../assets/logos/radiosants.png'
import sanitas from '../../assets/logos/sanitas.png'
import zara from '../../assets/logos/zara.png'
import sunstar from '../../assets/logos/sunstar.png'
import { Link } from "react-router-dom";

export const Contact = () => {
  const [currentSlide, setCurrentSlide] = useState(0);

  const logos = [
    {
      logo: apple,
      name: 'Apple',
      className: 'apple' // Clase específica para el logo de Apple
    },
    {
      logo: aws,
      name: 'Amazon'
    },
    {
      logo: adsmurai,
      name: 'Adsmurai'
    },
    {
      logo: bbva,
      name: 'BBVA'
    },
    {
      logo: loreal,
      name: 'Loreal'
    },
    {
      logo: mango,
      name: 'Mango'
    },
    {
      logo: radiosants,
      name: 'Radio Sants'
    },
    {
      logo: sanitas,
      name: 'Sanitas'
    },
    {
      logo: zara,
      name: 'Zara'
    },
    {
      logo: sunstar,
      name: 'Sunstar'
    },
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentSlide(currentSlide => (currentSlide + 1) % logos.length);
    }, 3000); // Cambia el tiempo de rotación aquí (en milisegundos)

    return () => clearInterval(interval);
  }, [logos.length]);

  const isMobile = useIsMobile();

  return (
    <footer className="container-fluid bg-green py-5">
      <div className="row justify-content-center">
        <div className="col-12 col-md-6 text-center">
          <div className="d-flex justify-content-center mb-3">
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://goo.gl/maps/VHkV1GEUes3Fx5KF6"
              className="contact-icon mx-2"
            >
              <FiMapPin />
            </a>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="tel:34673553301"
              className="contact-icon mx-2"
            >
              <FiPhone />
            </a>
            <a
              className="contact-icon mx-2"
              target="_blank"
              rel="noopener noreferrer"
              href="http://www.instagram.com/muerdete"
            >
              <FiInstagram />
            </a>
          </div>
          <a
            href="https://g.co/kgs/8QHcYfV"
            target="_blank"
            rel="noopener noreferrer"
            className="btn btn-white mb-4"
          >
            Ver ubicación en Google Maps
          </a>

          <div className="footer">
            <span><b>Han confiado en nosotros</b></span>
            <div className="logo-container">
              {logos.map((logo, index) => (
                <div
                  className={`logo-item ${logo.className || ''}`}
                  key={index}
                >
                  <img
                    src={logo.logo}
                    alt={`Logo ${logo.name}`}
                    title={logo.name}
                  />
                </div>
              ))}
            </div>
          </div>
          <div className="footer-links mt-3">
            <Link to="/condiciones-generales" className="me-3">
              Condiciones Generales
            </Link>
            <Link to="/politicas-privacidad">
              Política de Privacidad
            </Link>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Contact;