import { useEffect, useState } from "react";
import moment from "moment";
import "react-day-picker/dist/style.css";

import { useLocation } from "react-router-dom";
import { DayPicker } from "react-day-picker";
import { Modal } from "antd";
import {
  ClockCircleOutlined,
  TeamOutlined,
  LeftOutlined,
} from "@ant-design/icons";

import useGetLessons from "../../hooks/data/use-get-lessons";
import { es } from 'date-fns/locale';


const DateSelectionWithoutVoucher = ({
  course,
  goToPrevious,
  setClassSelected,
  quantity
}) => {
  const location = useLocation();
  const isBookingPage = location.pathname === "/reserva";
  const isBackofficePage = location.pathname === "/backoffice/agregar-reserva";
  const lessons = useGetLessons();
  const [classes, setClasses] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalData, setModalData] = useState();
  const [daySelected, setDaySelected] = useState();
  const [daysAvailable, setDaysAvailable] = useState([]);

  useEffect(() => {
    if (isBackofficePage) {
      setClasses(
        lessons?.filter(
          (lesson) =>
            lesson?.courseId === course?.id || lesson?.courseId === course?.value
        )
      );
    } else {
      setClasses(lessons?.filter((lesson) => lesson?.courseId === course?.id));
    }
  }, [lessons, course]);



  useEffect(() => {
    const filteredDates = classes
      ?.filter((cl) => {
        const lessonDate = new Date(cl?.lessonDate);
        const currentDate = new Date();
        const endDate = new Date();
        endDate.setDate(currentDate.getDate() + 180);

        return lessonDate >= currentDate && lessonDate <= endDate;
      })
      .map((cl) => new Date(cl?.lessonDate));

    setDaysAvailable(filteredDates);
  }, [classes]);

  const formatDate = (date) => {
    return moment(date).format("YYYY-MM-DD");
  };

  const GetIsDayDisabled = (day) => {
    return (
      daysAvailable?.find(
        (availableDate) => formatDate(day) === formatDate(availableDate)
      ) === undefined
    );
  };

  const setClassDate = (e) => {
    setIsModalOpen(true);
    setDaySelected(formatDate(e));
    setModalData(
      classes?.filter((cl) => formatDate(cl?.lessonDate) === formatDate(e))
    );
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const onSelectClass = (e) => {
    setIsModalOpen(false);
    setClassSelected(e);
  };

  return (
    <>
      <Modal
        title={`Horarios para el día ${daySelected} para ${quantity} personas.`}
        open={isModalOpen}
        footer={false}
        onCancel={handleCancel}
      >
        {modalData && modalData.some(data =>
          data.seats > 0 &&
          data.seats >= quantity

        ) ? (
          <div className="d-flex gap-4 py-4 flex-wrap">
            {modalData
              .filter(data =>
                data.seats > 0 &&
                data.seats >= quantity
              )
              .map(data => (
                <button
                  key={data.id}
                  onClick={() => onSelectClass(data)}
                  className="w-100 btn btn-primary shadow-xs border d-block text-center"
                >
                  <div className="d-flex gap-3 mb-2">
                    <ClockCircleOutlined className="my-auto" />
                    {data?.startHour}-{data?.finishHour}
                  </div>
                  <div className="d-flex gap-3">
                    <TeamOutlined className="my-auto" />
                    {data.seats} Personas
                  </div>
                </button>
              ))}
          </div>
        ) : (
          <p>El taller disponible en el día de la fecha no cuenta con capacidad para las personas requeridas.</p>
        )}
      </Modal>
      <div
        className={
          isBookingPage &&
          "card p-4 mx-auto shadow-xs border bg-gray-100 col-lg-4"
        }
      >
        {isBookingPage && (
          <>
            <div className="d-flex align-items-center gap-2 mb-4">
              <LeftOutlined />
              <a onClick={() => goToPrevious()}>Datos del bono</a>
            </div>
            <h3>{course?.title}</h3>
          </>
        )}
        <div className="card shadow-xs border mt-2 bg-gray-100 flex align-items-center" style={{ height: '100%' }}>
          <div className="card-body p-lg-4">
            <DayPicker
              locale={es}
              disabled={GetIsDayDisabled}
              mode="single"
              daySelected={daySelected}
              onSelect={setClassDate}
            />
          </div>
        </div>
      </div>
    </>
  );
};
export default DateSelectionWithoutVoucher;
