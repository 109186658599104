import React, { useState, useEffect } from "react";
import { Table, Button, Modal, Input, Form, DatePicker, InputNumber, message } from "antd";
import axios from "axios";
import dayjs from "dayjs";

const CouponsManager = () => {
    const [coupons, setCoupons] = useState([]);
    const [loading, setLoading] = useState(false);
    const [modalVisible, setModalVisible] = useState(false);
    const [editingCoupon, setEditingCoupon] = useState(null);
    const [form] = Form.useForm();

    useEffect(() => {
        fetchCoupons();
    }, []);

    const fetchCoupons = async () => {
        setLoading(true);
        try {
            const response = await axios.get(`${process.env.REACT_APP_URI}/v1/coupons`);
            setCoupons(response.data);
        } catch (error) {
            console.error("Error fetching coupons:", error);
            message.error("Error al obtener los cupones");
        } finally {
            setLoading(false);
        }
    };

    const handleDelete = async (id) => {
        Modal.confirm({
            title: "¿Estás seguro de eliminar este cupón?",
            content: "Esta acción no se puede deshacer.",
            okText: "Sí, eliminar",
            cancelText: "Cancelar",
            onOk: async () => {
                try {
                    await axios.delete(`${process.env.REACT_APP_URI}/v1/coupons/${id}`);
                    message.success("Cupón eliminado correctamente");
                    fetchCoupons();
                } catch (error) {
                    console.error("Error deleting coupon:", error);
                    message.error("Error al eliminar el cupón");
                }
            },
        });
    };

    const handleEdit = (coupon) => {
        setEditingCoupon(coupon);
        form.setFieldsValue({
            ...coupon,
            expiresAt: dayjs(coupon.expiresAt),
        });
        setModalVisible(true);
    };

    const handleAddNew = () => {
        setEditingCoupon(null);
        form.resetFields();
        setModalVisible(true);
    };

    const handleFormSubmit = async (values) => {
        const formattedValues = {
            ...values,
            expiresAt: new Date(values.expiresAt).toISOString(), // Convertir correctamente a Date
        };

        try {
            if (editingCoupon) {
                await axios.patch(`${process.env.REACT_APP_URI}/v1/coupons/${editingCoupon.id}`, formattedValues);
                message.success("Cupón actualizado correctamente");
            } else {
                await axios.post(`${process.env.REACT_APP_URI}/v1/coupons`, formattedValues);
                message.success("Cupón creado correctamente");
            }
            fetchCoupons();
            setModalVisible(false);
        } catch (error) {
            console.error("Error saving coupon:", error);
            message.error(error.response?.data?.message || "Error al guardar el cupón");
        }
    };

    const validateNumberInput = (event) => {
        const charCode = event.which || event.keyCode;
        if (charCode < 48 || charCode > 57) {
            event.preventDefault();
        }
    };

    const columns = [
        { title: "Código", dataIndex: "code", key: "code" },
        { title: "Monto (€)", dataIndex: "amount", key: "amount" },
        { title: "Usos máximos", dataIndex: "maxUses", key: "maxUses" },
        { title: "Usos restantes", dataIndex: "remainingUses", key: "remainingUses" },
        {
            title: "Fecha de vencimiento",
            dataIndex: "expiresAt",
            key: "expiresAt",
            render: (text) => dayjs(text).format("DD/MM/YYYY"),
        },
        {
            title: "Clientes que lo usaron",
            dataIndex: "clientsUsed",
            key: "clientsUsed",
            render: (clients) => (clients.length ? clients.length : "Ninguno"),
        },
        {
            title: "Acciones",
            key: "actions",
            render: (_, record) => (
                <>
                    <Button type="link" onClick={() => handleEdit(record)}>
                        Editar
                    </Button>
                    <Button type="link" danger onClick={() => handleDelete(record.id)}>
                        Eliminar
                    </Button>
                </>
            ),
        },
    ];

    return (
        <div className="p-4">
            <div className="d-flex justify-content-between mb-3">
                <h4>Administrar Cupones</h4>
                <Button type="primary" onClick={handleAddNew}>
                    + Nuevo Cupón
                </Button>
            </div>

            <Table
                dataSource={coupons}
                columns={columns}
                rowKey="id"
                loading={loading}
                pagination={{ pageSize: 5 }}
            />

            <Modal
                title={editingCoupon ? "Editar Cupón" : "Crear Nuevo Cupón"}
                open={modalVisible}
                onCancel={() => setModalVisible(false)}
                footer={null}
            >
                <Form form={form} layout="vertical" onFinish={handleFormSubmit}>
                    <Form.Item
                        name="code"
                        label="Código"
                        rules={[{ required: true, message: "Ingrese un código válido" }]}
                    >
                        <Input placeholder="Ej: DESCUENTO20" />
                    </Form.Item>

                    <Form.Item
                        name="amount"
                        label="Monto de Descuento (€)"
                        rules={[{ required: true, type: "number", min: 0, message: "Ingrese un número válido" }]}
                    >
                        <InputNumber
                            min={0}
                            step={0.5}
                            className="w-100"
                            onKeyPress={validateNumberInput}
                        />
                    </Form.Item>

                    <Form.Item
                        name="maxUses"
                        label="Cantidad máxima de usos"
                        rules={[{ required: true, type: "number", min: 1, message: "Debe ser un número mayor a 0" }]}
                    >
                        <InputNumber
                            min={1}
                            className="w-100"
                            onKeyPress={validateNumberInput}
                        />
                    </Form.Item>

                    <Form.Item
                        name="expiresAt"
                        label="Fecha de vencimiento"
                        rules={[{ required: true, message: "Seleccione una fecha válida" }]}
                    >
                        <DatePicker className="w-100" format="DD-MM-YYYY" />
                    </Form.Item>

                    <div className="text-end">
                        <Button onClick={() => setModalVisible(false)} style={{ marginRight: 8 }}>
                            Cancelar
                        </Button>
                        <Button type="primary" htmlType="submit">
                            {editingCoupon ? "Actualizar" : "Crear"}
                        </Button>
                    </div>
                </Form>
            </Modal>
        </div>
    );
};

export default CouponsManager;
