import BookingHero from "../components/booking/hero";
import StepsCarousel from "../components/booking/stepsCarousel";
import Navbar from "../components/navbar";
import React, { useRef } from 'react';

const Booking = () => {
  const talleresRef = useRef(null);

  return (
    <div className="position-relative">
      <Navbar />
      <BookingHero talleresRef={talleresRef} />
      <StepsCarousel />
    </div>
  );
};
export default Booking;
