import bookingBackground from "../../assets/images/hero/yellow_background.png";
import useIsMobile from "../../hooks/responsive/use-is-mobile";
import { useNavigate } from "react-router-dom";

const BookingHero = () => {
  const isMobile = useIsMobile();
  const navigate = useNavigate();

  const handleTalleresClick = () => {
    // Navegar a la página principal
    navigate('/');

    // Esperar a que la navegación se complete y luego desplazarse al elemento
    setTimeout(() => {
      const talleresElement = document.getElementById('talleres');
      if (talleresElement) {
        talleresElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    }, 100);
  };

  const styles = {
    bookingBackground: {
      backgroundImage: "url(" + bookingBackground + ")",
      minHeight: "90vh",
    },
    buttonsRow: {
      display: isMobile ? "block" : "flex",
    },
    button: {
      width: isMobile && "100%",
    },
  };

  return (
    <div className="page-header py-5 py-md-0" style={styles.bookingBackground}>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-8 col-sm-9 text-center mx-auto">
            <h1 className="mb-4">Reserva una plaza</h1>
            <p className="lead mb-sm-6 mb-4">
              Reserva una plaza para realizar uno de nuestros talleres. Puedes
              utilizar un bono que hayas recibido de regalo, o gestionar una
              nueva compra.
            </p>
            <div
              style={styles.buttonsRow}
              className="gap-4 justify-content-center"
            >
              <a
                style={styles.button}
                href="#booking-form"
                className="btn btn-white btn-lg"
              >
                Ya tengo un bono
              </a>
              <button
                onClick={handleTalleresClick}
                style={styles.button}
                className="btn btn-white btn-lg"
              >
                Ver talleres
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BookingHero;