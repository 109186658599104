import useIsMobile from "../../hooks/responsive/use-is-mobile";
import "../../assets/animations/animations.css";
import { Link } from "react-router-dom";

export const InfoPictureForOurSpace = ({
    title,
    subtitle,
    paragraphOne,
    image3,
}) => {
    const isMobile = useIsMobile();

    return (
        <div id="sobre-nosotros" className="container">
            <div className="sobre-nosotros row justify-content-between mx-auto pb-8 pt-8">
                <div className="col-lg-5">
                    <div className="mb-4">
                        {title && (
                            <h1 className={`mb-1 ${isMobile ? "text-2xl" : "text-6xl"}`} style={{ textAlign: "left", color: "#000" }}>
                                {title}
                            </h1>
                        )}
                        {subtitle && (
                            <h4 className={`${isMobile ? "text-xl" : "text-3xl"}`} style={{ textAlign: "left", color: "#000" }}>
                                {subtitle}
                            </h4>
                        )}
                    </div>
                    {paragraphOne && (
                        <p className={`${isMobile ? "text-lg" : "text-xl"}`} style={{ textAlign: "left", color: "#000" }}>
                            {paragraphOne}
                        </p>
                    )}



                    <Link
                        className={
                            isMobile
                                ? `btn btn-white btn-lg btn-lg text-xl mt-4`
                                : `btn btn-lg bg-purple text-white text-xl`
                        }
                        to="/nuestro-espacio"
                    >
                        Conoce más
                    </Link>
                </div>
                <div className="col-lg-6">
                    <img className="w-100 rounded-3" src={image3} alt="Nuestro Espacio" />
                </div>
                {/* 📌 Mapa de Google Maps */}
                <div className="mt-4">
                    <iframe
                        title="Mapa de Nuestro Espacio"
                        width="100%"
                        height="300"
                        style={{ border: 0, borderRadius: "15px" }}
                        loading="lazy"
                        allowFullScreen
                        referrerPolicy="no-referrer-when-downgrade"
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2993.993052942342!2d2.16322!3d41.38212!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x12a4a28a92c76e41%3A0x11f445c18df7c8cf!2sCarrer%20de%20Casanova%2C%203%2C%20Eixample%2C%2008011%20Barcelona%2C%20Espa%C3%B1a!5e0!3m2!1ses!2ses!4v1714932493841!5m2!1ses!2ses"
                    ></iframe>
                </div>
            </div>
            <div className="circle"></div>
        </div>
    );
};

export default InfoPictureForOurSpace;

