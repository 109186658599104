
import { Link, useNavigate } from 'react-router-dom';
import { S3_BUCKET_NAME } from '../../utils/constants';
import { Button, Modal } from 'antd';
import { useState } from 'react';
import LessonCalendarForClients from '../backoffice/lessons/lessonCalendarForClients';
import { CalendarOutlined, ShoppingCartOutlined } from '@ant-design/icons';
import { useCourse } from '../../context/Provider';
import './cardProduct.css';

export default function CardProduct({
  title,
  price,
  position,
  id,
  image,
  course
}) {
  const { courseSelected, setCourseSelected } = useCourse();
  const classList = "card-body " + "text-" + position;
  const courseTitle = title?.split(' ').join('-').toLowerCase();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const navigate = useNavigate();


  const handleCalendar = () => {
    setIsModalVisible(true);
  }


  const handleBuy = () => {
    const courseTitle = title?.split(' ').join('-').toLowerCase();
    setCourseSelected(course);
    navigate(`${courseTitle}/compra`)
  }

  const closeModal = () => {
    setIsModalVisible(false);
  };


  console.log("esto vael el course", course)

  return (
    <>
      <div className={`relative card card-product border mb-5 shadow-xl py-4 height-350 parent`}>
        {course.isMuerdete && (
          <div className="muerdete-banner">
            Producto exclusivo de Muerdete
          </div>
        )}
        <Link to={`/${courseTitle && courseTitle}`}>
          <div className="h-150-px w-150-px m-auto">
            <img className="w-100 h-100 rounded-circle" src={`${S3_BUCKET_NAME}/${id}/${image}`} alt='logo' />
          </div>
          <div className={classList + " mt-3"}>
            {(title) &&
              <h3 className="font-weight-bold mb-3">
                {title}
              </h3>
            }
            <p className="text-lg mb-3 text-body">
              {(title && !title.includes('Pasta')) ? 'Taller con degustación' : 'Taller sin degustación'} {/* Cambio aquí */}
            </p>
            <p style={{ fontSize: '10px' }}>(Click para mas información)</p>
            {(price) &&
              <h2 className="mb-0 mt-1 mb-3">
                {price?.toFixed(2)} €
              </h2>
            }

          </div>
        </Link>
        <div style={{ width: '100%', display: 'flex', justifyContent: 'space-around', alignItems: 'center' }}>
          <Button onClick={handleBuy} style={{ backgroundColor: '#008000', color: '#fff' }}>
            <ShoppingCartOutlined style={{ verticalAlign: 'middle', marginRight: '5px' }} />
            Comprar
          </Button>
          <Button onClick={handleCalendar} style={{ backgroundColor: '#6c757d', color: '#fff' }}>
            <CalendarOutlined style={{ verticalAlign: 'middle', marginRight: '5px' }} />
            Ver Calendario
          </Button>
        </div>
      </div>
      <Modal
        open={isModalVisible}
        onOk={closeModal}
        onCancel={closeModal}
      >
        <LessonCalendarForClients
          course={course}
        />
      </Modal>
    </>
  );
};
