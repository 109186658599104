import { useEffect, useState } from "react";
import moment from "moment";
import "react-day-picker/dist/style.css";
import { useLocation } from "react-router-dom";
import { DayPicker } from "react-day-picker";
import { Modal } from "antd";
import {
  ClockCircleOutlined,
  TeamOutlined,
  LeftOutlined,
  WarningOutlined,
} from "@ant-design/icons";
import "react-day-picker/dist/style.css";
import { es } from 'date-fns/locale';
import axios from "axios";


const DateSelection = ({
  atrapaloVoucherseatsQty,
  aladiniaVoucherseatsQty,
  isMuerdeteCase,
  course,
  goToNext,
  goToPrevious,
  setClassSelected,
  voucherId,
  lessons,
  vouchers,
  setCourse,
  voucherCreated,
  atrapaloQuantity
}) => {
  const location = useLocation();
  const isBookingPage = location.pathname === "/reserva";
  const isBackofficePage = location.pathname === "/backoffice/agregar-reserva";
  const voucher = vouchers?.find((v) => v.id === voucherId) || voucherCreated;
  const [classes, setClasses] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalData, setModalData] = useState();
  const [daySelected, setDaySelected] = useState();
  const [daysAvailable, setDaysAvailable] = useState([]);


  useEffect(() => {
    if (typeof course === 'string') {
      fetchCourse()
    }
    if (isBackofficePage) {
      setClasses(
        lessons?.filter(
          (lesson) =>
            lesson?.courseId === course?.id || lesson?.courseId === course?.value
        )
      );
    } else {
      setClasses(lessons?.filter((lesson) => lesson?.courseId === course?.id));
    }
  }, [lessons, course]);

  const fetchCourse = async () => {
    try {
      const coursesFromDB = await axios.get(process.env.REACT_APP_URI + "/v1/courses")
      const filteredCourse = coursesFromDB.data.filter((courseFilter => courseFilter.id === course));
      setCourse(filteredCourse[0])
    } catch (error) {
      console.log("error", error)
    }
  }



  useEffect(() => {
    const filteredDates = classes
      ?.filter((cl) => {
        const lessonDate = new Date(cl?.lessonDate);
        const currentDate = new Date();
        const endDate = new Date();
        endDate.setDate(currentDate.getDate() + 180);

        return lessonDate >= currentDate && lessonDate <= endDate;
      })
      .map((cl) => new Date(cl?.lessonDate));

    setDaysAvailable(filteredDates);
  }, [classes]);



  const formatDate = (date) => {
    return moment(date).format("YYYY-MM-DD");
  };

  const GetIsDayDisabled = (day) => {
    return (
      daysAvailable?.find(
        (availableDate) => formatDate(day) === formatDate(availableDate)
      ) === undefined
    );
  };

  const setClassDate = (e) => {
    setIsModalOpen(true);
    setDaySelected(formatDate(e));
    setModalData(
      classes?.filter((cl) => formatDate(cl?.lessonDate) === formatDate(e))
    );
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const onSelectClass = (e) => {
    setIsModalOpen(false);
    setClassSelected(e);
    if (isBookingPage || isMuerdeteCase) {
      goToNext();
    }
  };

  const getButtonClass = (seats) => {
    if (seats === 0) return 'btn-danger';
    if (seats === 1) return 'btn-warning'; // Naranja para 1 plaza
    return 'btn-success';                  // Verde para más de 1 plaza
  };


  console.log("esto teng en voucher", voucher)
  console.log("esto teng en voucher", voucherCreated)



  return (
    <>
      <Modal
        title={`Horarios para el día ${daySelected} para ${voucher?.seatsQty || atrapaloQuantity} personas.`}
        open={isModalOpen}
        footer={false}
        onCancel={handleCancel}
      >
        {modalData && modalData.some(data =>
          data.seats > 0 &&
          data.seats >= (voucher?.seatsQty || atrapaloQuantity || 1) &&
          (!aladiniaVoucherseatsQty || data.seats >= aladiniaVoucherseatsQty) &&
          (!atrapaloVoucherseatsQty || data.seats >= atrapaloVoucherseatsQty)
        ) ? (
          <div className="d-flex gap-4 py-4 flex-wrap">
            {modalData
              .filter(data =>
                data.seats > 0 &&
                data.seats >= (voucher?.seatsQty || atrapaloQuantity || 1) &&
                (!aladiniaVoucherseatsQty || data.seats >= aladiniaVoucherseatsQty) &&
                (!atrapaloVoucherseatsQty || data.seats >= atrapaloVoucherseatsQty)
              )
              .map(data => (
                <button
                  key={data.id}
                  onClick={() => onSelectClass(data)}
                  className={`w-100 btn shadow-xs border d-block text-center ${getButtonClass(data.seats)} 'border-primary border-2'}`}
                >
                  <div className="d-flex gap-3 mb-2">
                    <ClockCircleOutlined className="my-auto" />
                    {data?.startHour}-{data?.finishHour}
                  </div>
                  <div className="d-flex gap-3">
                    <TeamOutlined className="my-auto" />
                    {data.seats} {data.seats === 1 ? 'Persona' : 'Personas'}
                  </div>

                  {/* Mensaje de advertencia para cuando hay solo 1 plaza */}
                  {data.seats === 1 && (
                    <div className="mt-2 alert alert-warning py-1 mb-0 d-flex align-items-center gap-2">
                      <WarningOutlined />
                      <small>Válido solo para 1 bono de 1 persona</small>
                    </div>
                  )}
                </button>
              ))}
          </div>
        ) : (
          <p>El taller disponible en el día de la fecha no cuenta con capacidad para el bono solicitante.</p>
        )}
      </Modal>
      <div
        className={
          isBookingPage &&
          "card p-4 mx-auto shadow-xs border bg-gray-100 col-lg-4"
        }
      >
        {isBookingPage && (
          <>
            <div className="d-flex align-items-center gap-2 mb-4">
              <LeftOutlined />
              <a onClick={() => goToPrevious()}>Datos del bono</a>
            </div>
            <h3>{course?.title}</h3>
          </>
        )}
        {!!voucherId || isBackofficePage ? (
          <div className="w-300-px">
            <DayPicker
              locale={es}
              disabled={GetIsDayDisabled}
              mode="single"
              daySelected={daySelected}
              onSelect={setClassDate}
            />
          </div>
        ) : (
          <p>
            Antes de seleccionar la fecha del taller, debes ingresar los datos
            de tu bono.
          </p>
        )}
      </div>
    </>
  );
};
export default DateSelection;
