import { useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { Input, Form, notification, Select, DatePicker } from "antd";
import { Option } from "antd/es/mentions";
import useGetLessons from "../../../hooks/data/use-get-lessons";
import Navbar from "../../navbar";
import dayjs from "dayjs";
import { LeftOutlined } from "@ant-design/icons";
import es_ES from 'antd/es/date-picker/locale/es_ES';
import 'dayjs/locale/es';



const AddStudentToALesson = () => {
  const [api, contextHolder] = notification.useNotification();
  const lessons = useGetLessons();
  const { lessonId } = useParams();
  const lesson = lessons?.find((lesson) => lesson.id === lessonId);
  const [formData, setFormData] = useState({});
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [otherRequest, setOtherRequest] = useState("");
  const [quantitySeats, setQuantitySeats] = useState();


  dayjs.locale('es');



  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const openNotification = (message, description, placement) => {
    api[message.type]({
      message: message.content,
      description,
      placement,
    });
  };

  const handleEdit = async (e) => {
    e.preventDefault();
    setIsSubmitted(true);
    if (formData.specialRequests) {
      formData.specialRequests.push(otherRequest);
    } else {
      formData.specialRequests = [otherRequest];
    }

    formData.lessonId = lessonId;
    formData.seatsQuantity = quantitySeats;
    await axios
      .post(process.env.REACT_APP_URI + `/v1/lessons/addStudent`, formData)
      .then(() => {
        openNotification(
          { type: "success", content: "Alumno Agregado" },
          "El alumno se ha registrado exitosamente.",
          "bottomRight"
        );
        setTimeout(() => window.history.back(), 1700);
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.data.message === "No hay suficientes asientos disponibles en la lección.") {
            api.error({
              message: `No se pudo agregar al taller`,
              description: "No hay suficientes asientos disponibles en la lección.",
              placement: "bottomRight",
            });
          }
        }
      });
  };


  const handleSetValue = (e) => {
    const value = e.target.value;
    if (value > 0) {
      setQuantitySeats(value);
    }
    else {
      setQuantitySeats(0);
    }

  };


  const optionsForSelect = [
    { value: 'vegan', label: 'Vegano' },
    { value: 'vegetarian', label: 'Vegetariano' },
    { value: 'glutenFree', label: 'Sin gluten' },
    { value: 'noShellfish', label: 'Sin mariscos' },
    { value: 'lactoseIntolerant', label: 'Intolerancia a la lactosa' },
    { value: 'noNuts', label: 'Sin frutos secos' },
    { value: 'diabetes', label: 'Diabetes' },
    { value: 'hypertension', label: 'Hipertensión' },
    { value: 'heartDisease', label: 'Enfermedad cardíaca' },
  ];

  const handleOtherRequestChange = (value) => {
    setOtherRequest(value);
  };

  const handleSpecialRequestsChange = (selectedValues) => {
    setFormData({
      ...formData,
      specialRequests: selectedValues,
    });
  };

  const handleKeyPress = e => {
    const charCode = e.which || e.keyCode;

    if (charCode !== 46 && charCode > 31 && (charCode < 48 || charCode > 57)) {
      e.preventDefault();
    }
  };



  const disabledReason = () => {
    return (!formData.name || !formData.phone || !formData.email || !formData.birthdate || !quantitySeats || !quantitySeats > 0)
  }

  return (
    <>

      <Navbar />
      {contextHolder}
      <Form onSubmit={handleEdit} className='mb-2'>

        <div className='w-100'>
          <div className=" w-100 col-12 col-lg-8 p-3 p-md-5">
            <div className="cursor-pointer d-flex align-items-center gap-2 mb-4">
              <LeftOutlined />
              <a onClick={() => window.history.back()}>Volver</a>
            </div>
            <div className="card border" style={{ width: '100%' }}>
              <div className='d-flex justify-content-between align-items-center border-bottom'>
                <h6 className="p-lg-3">Datos del cliente</h6>
              </div>
              <div className="card-body">
                <div className="row">
                  <div className="col-md-4">
                    <div className="form-group">
                      <label>Nombre</label>
                      <Input
                        style={{ height: '40px' }}
                        className="form-control"
                        name="name"
                        value={formData?.name || ""}
                        onChange={handleChange}
                        placeholder="Ingresa el nombre"
                      />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group">
                      <label>Telefono</label>
                      <Input
                        style={{ height: '40px' }}
                        onKeyPress={handleKeyPress}
                        type="text"
                        className="form-control"
                        name="phone"
                        value={formData?.phone || ""}
                        onChange={handleChange}
                        placeholder="Ingresa el telefono"
                      />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group">
                      <label>Email</label>
                      <Input
                        style={{ height: '40px' }}
                        type="email"
                        className="form-control"
                        name="email"
                        value={formData?.email || ""}
                        onChange={handleChange}
                        placeholder="Ingresa el email"
                      />
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-4">
                    <div className="form-group">
                      <label>Fecha de nacimiento</label>
                      {/* <Input
                        style={{ height: '40px' }}
                        onKeyPress={handleKeyPress}
                        type="text"
                        className="form-control"
                        name="birthdate"
                        value={formData?.phone || ""}
                        onChange={handleChange}
                        placeholder="DD-MM-AAAA"
                      /> */}
                      <DatePicker
                        style={{ height: "40px" }}
                        locale={es_ES}
                        firstDayOfWeek={1}
                        format="DD-MM-YYYY" // 📌 Mostramos en formato DD-MM-YYYY en el UI
                        placeholder="Seleccionar"
                        size="middle"
                        className="w-100"
                        name="birthdate"
                        onChange={(date) => {
                          if (date) {
                            setFormData({
                              ...formData,
                              birthdate: date.format("YYYY-MM-DD"), // 📌 Guardamos en formato YYYY-MM-DD para el backend
                            });
                          } else {
                            setFormData({
                              ...formData,
                              birthdate: "",
                            });
                          }
                        }}
                        disabledDate={(current) => current && current > dayjs().startOf("day")}
                      />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group">
                      <label>Selecciona uno o más requerimientos ( opcional ) </label>
                      <Select
                        mode="multiple"
                        placeholder="Selecciona o agrega pedidos especiales (opcional)"
                        value={formData?.specialRequests}
                        style={{ width: '100%', height: '40px' }}
                        onChange={handleSpecialRequestsChange}
                      >
                        {optionsForSelect.map((el) => (
                          <Option value={el.value} key={el.value}>
                            {el.label}
                          </Option>
                        ))}
                      </Select>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group">
                      <label>Agrega otro requerimiento distinto</label>
                      <Input
                        style={{ height: '40px' }}
                        type='text'
                        name="other"
                        placeholder="Otro (opcional)"
                        value={otherRequest}
                        onChange={(e) => handleOtherRequestChange(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
                <div class="form-group w-25">
                  <label>Cantidad de personas</label>
                  <Input
                    style={{ height: '40px' }}
                    type="number"
                    name="seats"
                    min={1}
                    onKeyPress={(e) => {
                      const keyCode = e.which || e.keyCode;
                      if ((keyCode < 48 || keyCode > 57) && keyCode !== 8) {
                        e.preventDefault();
                      }
                    }}
                    onChange={(e) => handleSetValue(e)}
                    placeholder="Ingrese cantidad de personas"
                  />
                </div>
              </div>
            </div>
            <div className="justify-content-right">
              <button
                disabled={disabledReason()}
                onClick={handleEdit}
                className="w-200-px btn btn-primary float-end me-4 mt-2"
                type="submit"
              >
                Guardar datos
              </button>
            </div>
          </div>
        </div>
      </Form>
    </>

  );
};

export default AddStudentToALesson;
