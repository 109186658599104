import React, { useState, Component, useEffect } from "react";
import { Carousel } from "antd";
import BookingStatus from "./bookingStatus";
import DateSelection from "./dateSelection";
import PersonalData from "./personalData";
import VoucherData from "./voucherData";
import backgroundImage from "../../assets/images/hero/shaped_background_hero.svg";
import axios from "axios";


const CarouselComponent = ({ isMuerdeteCase }) => {
  const [slider, setSlider] = useState(0);
  const [course, setCourse] = useState({});
  const [lessons, setLessons] = useState([])
  const [vouchers, setVouchers] = useState([]);
  const [atrapaloQuantity, setAtrapaloQuantity] = useState()



  useEffect(() => {
    const fetchLessons = async () => {
      try {
        const response = await axios.get(process.env.REACT_APP_URI + '/v1/lessons');
        setLessons(response.data);
      } catch (error) {
        console.error('Error fetching lessons:', error);
      }
    };

    const fetchVouchers = async () => {
      try {
        const response = await axios.get(process.env.REACT_APP_URI + '/v1/vouchers');
        setVouchers(response.data);
      } catch (error) {
        console.error('Error fetching vouchers:', error);
      }
    };

    fetchLessons();
    fetchVouchers();
  }, []);
  const [classSelected, setClassSelected] = useState();
  const [voucherId, setVoucherId] = useState();
  const [voucherCreated, setVoucherCreated] = useState();
  const [otherRequest, setOtherRequest] = useState()
  const [personalData, setPersonalData] = useState({
    name: "",
    email: "",
    phone: "",
    birthDate: "",
    specialRequests: []
  });

  const handleOtherRequestChange = (e) => {
    setOtherRequest(e)
  }
  const handlePersonalDataChange = (e) => {
    setPersonalData({
      ...personalData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSpecialRequestsChange = (selectedValues) => {
    setPersonalData({
      ...personalData,
      specialRequests: selectedValues,
    });
  };

  const handleBirthdateSelection = (dateString) => {
    setPersonalData({
      ...personalData,
      birthDate: dateString,
    })
  }


  return (
    <ClassComponent
      atrapaloQuantity={atrapaloQuantity}
      setAtrapaloQuantity={setAtrapaloQuantity}
      handleBirthdateSelection={handleBirthdateSelection}
      handleSpecialRequestsChange={handleSpecialRequestsChange}
      handlePersonalDataChange={handlePersonalDataChange}
      lessons={lessons}
      vouchers={vouchers}
      isMuerdeteCase={isMuerdeteCase}
      slider={slider}
      setVoucherCreated={setVoucherCreated}
      voucherCreated={voucherCreated}
      course={course}
      handleOtherRequestChange={handleOtherRequestChange}
      otherRequest={otherRequest}
      classSelected={classSelected}
      personalData={personalData}
      setVoucherId={(voucherId) => setVoucherId(voucherId)}
      voucherId={voucherId}
      setSlider={(slider) => setSlider(slider)}
      setCourse={(course) => setCourse(course)}
      setClassSelected={(classSelected) => setClassSelected(classSelected)}
      setPersonalData={(personalData) => setPersonalData(personalData)}
    />
  );
};
class ClassComponent extends Component {



  constructor(props) {
    super(props);
    this.next = this.next.bind(this);
    this.previous = this.previous.bind(this);
    this.goTo = this.goTo.bind(this);
    this.carousel = React.createRef();
  }
  next() {
    this.carousel.next();
  }
  previous() {
    this.carousel.prev();
  }
  goTo(sliderNumber, dontAnimate) {
    this.carousel.goTo(sliderNumber, dontAnimate);
  }
  render() {
    const {
      atrapaloQuantity,
      setAtrapaloQuantity,
      handleSpecialRequestsChange,
      handleBirthdateSelection,
      handlePersonalDataChange,
      vouchers,
      lessons,
      voucherId,
      voucherCreated,
      setVoucherCreated,
      setVoucherId,
      course,
      slider,
      setSlider,
      setCourse,
      handleOtherRequestChange,
      otherRequest,
      classSelected,
      setClassSelected,
      personalData,
      isMuerdeteCase,
    } = this.props;
    const carouselProps = {
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      slider: slider,
    };
    const styles = {
      carousel: {
        backgroundSize: "cover",
        backgroundImage: "url(" + backgroundImage + ")",
        padding: "6rem 1rem",
      },
    };


    console.log("esto vale voucher", voucherCreated)

    return (
      <div style={styles.carousel} id="booking-form">
        <BookingStatus slider={slider} />
        <Carousel
          afterChange={(e) => setSlider(e)}
          ref={(node) => (this.carousel = node)}
          {...carouselProps}
        >
          <VoucherData
            setAtrapaloQuantity={setAtrapaloQuantity}
            setVoucherCreated={setVoucherCreated}
            setVoucherId={(voucherId) => setVoucherId(voucherId)}
            course={course}
            setCourse={(course) => setCourse(course)}
            goToPrevious={this.previous}
            goToNext={this.next}
          />
          <DateSelection
            atrapaloQuantity={atrapaloQuantity}
            voucherId={voucherId}
            course={course}
            setCourse={setCourse}
            setClassSelected={(classSelected) =>
              setClassSelected(classSelected)
            }
            voucherCreated={voucherCreated}
            goToPrevious={this.previous}
            goToNext={this.next}
            isMuerdeteCase={isMuerdeteCase}
            lessons={lessons}
            vouchers={vouchers}
          />
          <PersonalData
            vouchers={vouchers}
            atrapaloQuantity={atrapaloQuantity}
            handleSpecialRequestsChange={handleSpecialRequestsChange}
            handleBirthdateSelection={handleBirthdateSelection}
            handleOtherRequestChange={handleOtherRequestChange}
            otherRequest={otherRequest}
            voucherCreated={voucherCreated}
            voucherId={voucherId}
            classSelected={classSelected}
            personalData={personalData}
            isStepper={true}
            handlePersonalDataChange={handlePersonalDataChange}
            goToPrevious={this.previous}
            goToNext={this.next}
          />
        </Carousel>
      </div>
    );
  }
}
export default CarouselComponent;
