import React, { useEffect, useState } from "react";
import { Input, Select, notification } from "antd";
import { useLocation } from "react-router-dom";
import { CheckCircleOutlined, WarningOutlined } from "@ant-design/icons";
import useGetCourses from "../../hooks/data/use-get-courses";
import axios from "axios";

const VOUCHER_STATUS_INACTIVE = 7;
const VOUCHER_STATUS_USED = 6;
const VOUCHER_STATUS_ANULADO = 2;
const VOUCHER_STATUS_ABIERTO = 1;
//test
const VoucherData = ({ setVoucherId, course, setCourse, goToNext, setVoucherCreated, setAtrapaloQuantity }) => {
  const [api, contextHolder] = notification.useNotification();
  const [showCourseName, setShowCourseName] = useState(false);
  const [voucherExpired, setVoucherExpired] = useState(false);
  const [voucher, setVoucher] = useState();
  const courses = useGetCourses();
  const location = useLocation();
  const [inactiveError, setInactiveError] = useState(false);
  const [aladiniaGenericInfo, setAladiniaGenericInfo] = useState(false);
  const [isAtrapalo, setIsAtrapalo] = useState(false);
  const [selectedGenericCourse, setSelectedGenericCourse] = useState();
  const isBackofficePage = location.pathname === "/backoffice/agregar-reserva";
  const [quantitySeats, setQuantitySeats] = useState();


  const isBookingPage = location.pathname === "/reserva";
  const [voucherData, setVoucherData] = useState({
    source: "",
    tracker: "",
    code: "",
  });


  const handleVoucherDataChange = (e) => {
    setInactiveError(false)
    setVoucherData({
      ...voucherData,
      [e.target.name]: e.target.value,
    });
  };
  const openNotification = (message, description, placement) => {
    api[message.type]({
      message: message.content,
      description,
      placement,
    });
  };


  const findCourseByService = (service) => {
    let selectedCourse;
    if (service.toLowerCase().includes("elegir")) {
      setAladiniaGenericInfo(true)
    }
    else if (
      service.toLowerCase().includes("sushi") ||
      service.toLowerCase().includes("japonesa")
    ) {
      selectedCourse = courses?.find((course) =>
        course.title.toLowerCase().includes("sushi")
      );
    } else if (
      service.toLowerCase().includes("mexicano") ||
      service.toLowerCase().includes("mexicana")
    ) {
      selectedCourse = courses?.find((course) =>
        course.title.toLowerCase().includes("mexicano")
      );
    } else if (service.toLowerCase().includes("pasta")) {
      selectedCourse = courses?.find((course) =>
        course.title.toLowerCase().includes("pasta")
      );
    }
    return selectedCourse;
  };

  const findCourseSeats = (people) => {
    console.log("esto tengo en people", people)
    let seats;

    if (people.toLowerCase().includes("dos personas")) {
      seats = 2;
    } else if (people.toLowerCase().includes("una persona")) {
      seats = 1;

    }
    console.log("devuelvo seats", seats)
    return seats;
  };



  const handleMuerdeteCase = async () => {
    try {
      const urlCall = `${process.env.REACT_APP_URI}/v1/vouchers/tracker/${voucherData.tracker}/code/${voucherData.code}`;
      const response = await axios.get(urlCall);
      if (response.data) {
        setVoucher(response.data);

        if (
          !!response.data &&
          response.data.used === false &&
          new Date(response.data.expiryDate) > new Date()
        ) {
          setShowCourseName(true);
          setVoucherId(response.data.id);
          setTimeout(goToNext, 1000);
        } else if (new Date(response.data.expiryDate) < new Date()) {
          setVoucherExpired(true);
        }
        else if (response.data.used === true) {
          openNotification(
            { type: "error", content: "Bono ya utilizado" },
            "Este bono ya fue utilizado previamente.",
            "bottomRight"
          );
        }
        else {
          openNotification(
            { type: "error", content: "Bono no encontrado" },
            "Revisa los datos que recibiste en tu correo electrónico o contacta con nosotros.",
            "bottomRight"
          );
        }
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const handleAtrapaloCase = async () => {

    setIsAtrapalo(true)

    if (selectedGenericCourse && quantitySeats) {
      try {

        const vouchersPostData = {
          source: voucherData.source,
          tracker: voucherData.tracker,
          code: voucherData.code,
          courseId: selectedGenericCourse,
          used: false,
          seatsQty: quantitySeats,
        };

        const newVoucher = await axios.post(
          `${process.env.REACT_APP_URI}/v1/vouchers`,
          vouchersPostData
        );
        setAtrapaloQuantity(quantitySeats);
        setCourse(selectedGenericCourse);
        setShowCourseName(true);
        setVoucherId(newVoucher?.data.id);
        setTimeout(goToNext, 1000);
        setVoucherCreated(newVoucher?.data)
      }
      catch (error) {
        console.error("Error al hacer la consulta a aladinia:", error);
      }
    }
  };


  const handleAladiniaCase = async () => {

    try {
      let voucher = await axios.get(
        `${process.env.REACT_APP_URI}/v1/voucher/${voucherData.tracker}`
      );

      console.log("esto tengo de aladinia", voucher)


      if (voucher.data.status_id === VOUCHER_STATUS_INACTIVE) {
        setInactiveError(true)
        return
      }

      if ((voucher.data.status_id === 1)) {
        setVoucher(voucher.data);
        const selectedCourse = findCourseByService(voucher.data.service);
        const seats = findCourseSeats(voucher.data.people);


        const vouchersPostData = {
          source: voucherData.source,
          tracker: voucherData.tracker,
          code: voucherData.code,
          purchaseDate: voucherData.purchase_date,
          expiryDate: voucherData.valid_until,
          courseId: selectedGenericCourse ? selectedGenericCourse : selectedCourse.id,
          seatsQty: seats,
          used: false,
        };



        const newVoucher = await axios.post(
          `${process.env.REACT_APP_URI}/v1/vouchers`,
          vouchersPostData
        );
        setCourse(selectedCourse || selectedGenericCourse);
        setShowCourseName(true);
        setVoucherId(newVoucher?.data.id);
        setTimeout(goToNext, 1000);
        setVoucherCreated(newVoucher?.data)
      } else if (voucher.data.status_id === VOUCHER_STATUS_USED) {
        openNotification(
          { type: "warning", content: "Bono ya utilizado" },
          "Este bono ya ha sido utilizado anteriormente.",
          "bottomRight"
        );
      } else if (voucher.data.status_id === VOUCHER_STATUS_ANULADO) {
        openNotification(
          { type: "warning", content: "Bono anulado" },
          "Este bono ha sido anulado.",
          "bottomRight"
        );
      } else {
        openNotification(
          { type: "error", content: "Bono no encontrado" },
          "No pudimos encontrar tu Bono",
          "bottomRight"
        );
      }
    } catch (error) {
      console.error("Error al hacer la consulta a aladinia:", error);
    }
  };

  const onVoucherSearch = () => {
    switch (voucherData.source) {
      case "muerdete":
        handleMuerdeteCase();
        break;
      case "aladinia":
        handleAladiniaCase();
        break;
      case "atrapalo":
        handleAtrapaloCase();
      default:
        // Otros casos
        break;
    }
  };


  useEffect(() => {
    // Verifica si el voucher ha sido obtenido exitosamente.
    if (voucher && Object.keys(voucher).length !== 0) {
      // Establece el ID del voucher para uso externo si es necesario
      if (!aladiniaGenericInfo) {
        setVoucherId(voucher.id);
      }
      // Encuentra y establece el curso correspondiente al voucher
      const matchingCourse = courses.find(course => course.id === voucher.courseId);
      if (matchingCourse) {
        setCourse(matchingCourse);
      }

      // Puedes agregar aquí cualquier lógica adicional que necesites ejecutar cuando se obtiene un voucher
    }
  }, [voucher, courses, setVoucherId, setCourse]);

  const disabledReason = () => {

    if (isBackofficePage) {
      return (!voucherData.tracker || !voucherData.code)
    }
    const isAladinia = voucherData.source === 'aladinia';
    const isAtrapalo = voucherData.source === 'atrapalo';


    if (isAtrapalo) {
      return (!voucherData.source || !voucherData.tracker || !selectedGenericCourse || !voucherData.code || !quantitySeats || !+quantitySeats > 0);
    }

    if (isAladinia && aladiniaGenericInfo) {
      return (!voucherData.source || !voucherData.tracker || !selectedGenericCourse || !voucherData.code);
    }

    if (isAladinia && !aladiniaGenericInfo) {
      return (!voucherData.source || !voucherData.tracker || !voucherData.code);
    }

    return (!voucherData.source || !voucherData.tracker || !voucherData.code);
  }

  const handleSetValue = (e) => {
    const value = e.target.value;
    if (value > 0) {
      setQuantitySeats(value);
      isAtrapalo && setAtrapaloQuantity(value);
    }
    else {
      setQuantitySeats(0);
      setAtrapaloQuantity(0);
    }

  };

  return (
    <div
      className={
        isBookingPage &&
        "mx-auto card shadow-xs border bg-gray-100 col-lg-4 col-sm-12"
      }
      id="voucher"
    >
      {contextHolder}
      <div className="card-body p-lg-4">
        <h3>Datos del bono</h3>

        <div className="form-group">
          <label>Plataforma de bono regalo</label>
          <Select
            name="source"
            className="w-100"
            placeholder="Seleccionar"
            optionFilterProp="children"
            onChange={(value) =>
              setVoucherData({
                ...voucherData,
                source: value,
              })
            }
            filterOption={(input, option) =>
              (option?.label ?? "")?.toLowerCase().includes(input.toLowerCase())
            }
            options={isBackofficePage ? [{ value: 'muerdete', label: "Muérdete" }] :
              [
                {
                  value: "aladinia",
                  label: "Aladinia",
                },
                {
                  value: "muerdete",
                  label: "Muérdete",
                },
                {
                  value: "atrapalo",
                  label: "Atrápalo",
                },
              ]}
          />
        </div>
        <div class="form-group">
          <label>Localizador</label>
          <Input
            name="tracker"
            onChange={(e) => handleVoucherDataChange(e)}
            placeholder="123456"
          />
        </div>
        <div class="form-group">
          <label>{voucherData && voucherData.source === 'aladinia' ? 'Código de Consumo' : 'Código de Reserva'}</label>
          <Input
            name="code"
            onChange={(e) => handleVoucherDataChange(e)}
            placeholder="123456"
          />
        </div>
        {
          (aladiniaGenericInfo || voucherData.source === 'atrapalo') &&
          <div className="form-group">
            <label>Talleres disponibles</label>
            <Select
              name="source"
              className="w-100"
              placeholder="Seleccionar"
              optionFilterProp="children"
              onChange={value => setSelectedGenericCourse(value)}
              options={
                courses
                  ?.filter(course => voucherData.source !== 'atrapalo' || !course.isMuerdete) // Filtra los cursos cuando es "Atrápalo"
                  .map(course => ({
                    value: course.id,
                    label: course.title
                  }))
              }
            />
          </div>
        }
        {
          voucherData.source === 'atrapalo' &&
          <div class="form-group">
            <label>Cantidad de personas</label>
            <Input
              type="number"
              name="seats"
              min={1}
              onKeyPress={(e) => {
                const keyCode = e.which || e.keyCode;
                if ((keyCode < 48 || keyCode > 57) && keyCode !== 8) {
                  e.preventDefault();
                }
              }}
              onChange={(e) => handleSetValue(e)}
              placeholder="Ingrese cantidad de personas"
            />
          </div>
        }
        {voucherExpired === true && (
          <div className="card border p-lg-3 bg-yellow">
            <div className="text-lead m-0  d-flex gap-3 align-items-center">
              <WarningOutlined />
              <a
                href="https://api.whatsapp.com/send/?phone=%2B34673553301&text=Mi+voucher+ya+ha+caducado.+Necesito+ayuda."
                target="_blank" rel="noreferrer"
              >
                Este bono ya ha caducado. Contáctanos por Whatsapp.
              </a>
            </div>
          </div>
        )}
        {inactiveError === true && (
          <div className="card border p-lg-3 bg-yellow">
            <div className="text-lead m-0  d-flex gap-3 align-items-center">
              <WarningOutlined />
              Este bono se encuentra inactivo. Contactar a Aladinia.
            </div>
          </div>
        )}

        <div className="d-flex gap-3 my-auto justify-content-between">
          <button disabled={disabledReason()} class="mt-4 btn btn-primary mb-0" onClick={onVoucherSearch}>
            Búscar
          </button>

          {showCourseName && (
            <p className="my-auto d-flex gap-2 align-items-center">
              <CheckCircleOutlined />
              {course?.title}
            </p>
          )}
        </div>

      </div>
    </div>
  );
};

export default VoucherData;
