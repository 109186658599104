import { Switch, Input, DatePicker, Select } from 'antd';
import { Option } from 'antd/es/mentions';
import dayjs from 'dayjs';
import { optionsForPasta, optionsForSelect, optionsForSelectMexican } from '../../utils/constants';
import es_ES from 'antd/es/date-picker/locale/es_ES';
import 'dayjs/locale/es';
import { useCourse } from '../../context/Provider';
import { useState } from 'react';



export default function CartItem({
  course,
  handleSelectChange,
  setDateIsWanted,
  otherRequest,
  handleOtherRequestChange,
  personalData,
  setPersonalData,
  isAGift,
  setIsAGift,
  giftData,
  quantity,
  classWanted,
  selectedLesson,
  setGiftData }) {
  const { TextArea } = Input;
  const { courseSelected, setCourseSelected } = useCourse();
  const [emailError, setEmailError] = useState(""); // 📌 Estado para manejar el error del email





  const handlePersonalDataChange = (e) => {
    const { name, value } = e.target;

    if (name === "email") {
      // 📌 Validar que el email sea de Gmail
      if (!/^[a-zA-Z0-9._%+-]+@gmail\.com$/.test(value)) {
        setEmailError("Solo se admiten correos de Gmail (@gmail.com)");
      } else {
        setEmailError("");
      }
    }

    setPersonalData({
      ...personalData,
      [name]: value,
    });
  };

  const handleGiftDataChange = (e) => {
    setGiftData({
      ...giftData,
      [e.target.name]: e.target.value,
    })
  }

  dayjs.locale('es');




  const handleSpecialRequestsChange = (selectedValues) => {
    setPersonalData({
      ...personalData,
      specialRequests: selectedValues,
    });
  };






  return (
    <>
      <div className="card shadow-xs border bg-gray-100">
        <div className="card-body p-lg-4">
          <div className='d-flex flex-col justify-content-between'>
            <h5 className="mb-4">Tus datos  :
              Solo de la persona que reserva</h5>
            <div>Es un regalo <Switch onChange={(e) => setIsAGift(e)} /></div>

          </div>
          <div className='d-flex gap-3'>
            <Input name="name" className='mb-3' placeholder="Nombre y apellido" onChange={(e) => handlePersonalDataChange(e)} />
            <div style={{ width: "100%" }}>
              <Input
                name="email"
                className="mb-1"
                placeholder="Email"
                onChange={handlePersonalDataChange}
              />
              {emailError && <p className="text-danger text-xs">{emailError}</p>}
            </div>          </div>
          <div className='d-flex gap-3 pb-3'>
            <Input name="phone" placeholder="Telefono" onChange={(e) => handlePersonalDataChange(e)} />
            <DatePicker
              locale={es_ES}
              firstDayOfWeek={1}
              format="DD-MM-YYYY"
              placeholder='Fecha de nacimiento'
              name="birthDate" size="middle"
              className='w-100'
              onChange={(date, dateString) => setPersonalData({
                ...personalData,
                birthDate: date.format("YYYY-MM-DD")
              })} disabledDate={current => current && current > dayjs().startOf('day')} />
          </div>
          <div className='d-flex pb-3 gap-3'>
            {/* {
              course?.title && 
              <Select
              mode="multiple"
              placeholder="Selecciona o agrega pedidos especiales (opcional)"
              value={personalData.specialRequests}
              style={{ flex: 1 }}
              onChange={handleSpecialRequestsChange}
              options={
                course?.title.toLowerCase().includes('mexicano') ?
                  optionsForSelectMexican :
                  course?.title.toLowerCase().includes('pasta') ?
                    optionsForPasta :
                    optionsForSelect
              } />
            } */}
            {
              courseSelected &&
              <Select
                mode="multiple"
                placeholder="Selecciona o agrega pedidos especiales (opcional)"
                value={personalData.specialRequests}
                style={{ flex: 1 }}
                onChange={handleSpecialRequestsChange}
                options={
                  courseSelected?.title.toLowerCase().includes('mexicano') ?
                    optionsForSelectMexican :
                    courseSelected?.title.toLowerCase().includes('pasta') ?
                      optionsForPasta :
                      optionsForSelect
                } />
            }
          </div>
          <div className='d-flex gap-3 '>
            <Input
              name="other"
              className='mb-3'
              placeholder="Otro (opcional)"
              value={otherRequest}
              onChange={(e) => handleOtherRequestChange(e.target.value)}
            />
          </div>
          <div className="d-flex justify-content-between align-items-center">
            <div className="d-flex align-items-center gap-2 flex-nowrap">
              <h5 className="whitespace-nowrap">Cantidad de asistentes:</h5>
              <Select className="w-auto" size="small" value={quantity} onChange={handleSelectChange}>
                {[...Array(9)].map((_, index) => (
                  <Option key={index + 1} value={index + 1}>
                    {index + 1}
                  </Option>
                ))}
              </Select>
            </div>
            <div >Elegir Fecha <Switch defaultChecked={selectedLesson && true} onChange={(e) => setDateIsWanted(e)} /></div>
          </div>
          {
            (classWanted && (quantity > classWanted.seats)) &&
            <div className='text-xs text-danger'>
              La cantidad seleccionada supera las plazas disponibles.
            </div>
          }

          {isAGift &&
            <div className='border-top pt-4 mt-3'><h5>Enviar bono regalo a:</h5>
              <Input name="emailTo" className='mb-4 mt-3' placeholder="Email" onChange={(e) => handleGiftDataChange(e)} />
              <h5 className="mb-4">Personaliza el regalo</h5>
              <Input name="from" className='mb-3' placeholder="De parte de" onChange={(e) => handleGiftDataChange(e)} />
              <Input name="toName" className='mb-3' placeholder="Nombre de quien recibe" onChange={(e) => handleGiftDataChange(e)} />
              <TextArea name="message" rows={1} placeholder="Mensaje" maxLength={100} onChange={(e) => handleGiftDataChange(e)} />
            </div>
          }
        </div>
      </div>

    </>
  );
}
